import { BLOCKS, PAGE_BLOCKS } from '../../../types';
import { Editor, Transforms, Node } from 'slate';

import { Page, Paragraph } from '../../../helpers/elements';
import { genID } from '@churni/core';

export const withPageSchema = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    // Normalize section block
    if (path.length === 1 && node.type === BLOCKS.BLOCK_PAGE) {
      // Ensure section has an id
      if (!node.id) {
        Transforms.setNodes(editor, { id: genID() }, { at: path });
      }

      if (
        !node.children[0] ||
        node.children[0].type !== BLOCKS.BLOCK_PAGE_BODY
      ) {
        Transforms.insertNodes(editor, Page.createBody(), {
          at: path.concat(0)
        });
      }

      if (
        !node.children[1] ||
        node.children[1].type !== BLOCKS.BLOCK_PAGE_ACTIONS
      ) {
        Transforms.insertNodes(editor, Page.createPageActions(), {
          at: path.concat(1)
        });
      }

      // Delete other unknwon children
      if (node.children[2]) {
        Transforms.removeNodes(editor, {
          at: path.concat(2)
        });
      }
    }

    // Normalize body block
    if (path.length === 2 && node.type === BLOCKS.BLOCK_PAGE_BODY) {
      // Ensure body has at least one children and isn't a single text leaf and has a actions block

      // If it has zero children, add one paragraph
      if (node.children.length === 0 || !node.children[0].type) {
        Transforms.insertNodes(editor, Paragraph.create(), {
          at: path.concat(0)
        });
      }

      const pageBlocks = Object.values(PAGE_BLOCKS);
      for (const [child, childPath] of Node.children(editor, path)) {
        // Ensure body's children are allowed blocks, otherwise delete them
        if (!pageBlocks.includes(child.type)) {
          Transforms.removeNodes(editor, {
            at: childPath
          });
        }
      }
    }

    // Normalize Body.SectionsActions block
    if (path.length === 2 && node.type === BLOCKS.BLOCK_PAGE_ACTIONS) {
      const hasPrimaryButton = node.children.find(
        child => child.type === BLOCKS.BLOCK_PAGE_ACTION_PRIMARY
      );

      if (!node.children[0] || !hasPrimaryButton) {
        Transforms.insertNodes(editor, Page.createPageActionPrimary(), {
          at: path.concat(0)
        });
      }

      if (node.children[2]) {
        return Transforms.removeNodes(editor, {
          at: path.concat(2)
        });
      }
    }
    return normalizeNode([node, path]);
  };

  return editor;
};
