import * as React from 'react';
import { RenderElementProps, ReactEditor, useEditor } from 'slate-react';

import { Box, Icon, Button, Tooltip } from '@churni/styleguide';
import { BlockWrapper } from '../common';
import { LogicJumpEdit } from './LogicJumpEdit';
import { useIsAnswerMode, JumpsContext } from '../../containers';
import { Editor, Path, Transforms } from 'slate';
import { BLOCK_JUMP } from '../../types';
import { Jump } from '../../helpers';

/*
 * Renderer for a paragraph block.
 */
export function BlockJump(props: RenderElementProps): React.ReactElement {
  const { children, element, attributes } = props;

  const isAnswerMode = useIsAnswerMode();
  const editor = useEditor();
  React.useContext(JumpsContext);
  // const pages = React.useContext(PagesContext);

  if (isAnswerMode) {
    return null;
  }

  const elementPath = ReactEditor.findPath(editor, element);

  const [nextNode] = Editor.node(editor, Path.next(elementPath));
  // const previousPages = pages.filter(p => p[1][0] < elementPath[0]);
  // const lastPage = previousPages[previousPages.length - 1];
  // console.log(lastPage);
  // let domNode;
  // try {
  //   domNode = ReactEditor.toDOMNode(editor, lastPage[0]);
  // } catch (err) {}

  // if (domNode) {
  //   console.log(domNode.getBoundingClientRect());
  // }

  const isLastJump = nextNode.type !== BLOCK_JUMP;

  return (
    <Box
      {...attributes}
      sx={{ position: 'relative', userSelect: 'none' }}
      mb={isLastJump ? 4 : 0}
      contentEditable={false}
    >
      <Box
        py={2}
        px={3}
        my={'4px'}
        sx={{
          color: 'primary',
          bg: 'primaryLightest',
          border: 1,
          borderColor: 'primary',
          borderRadius: 'rounded',
          whiteSpace: 'nowrap'
        }}
      >
        <BlockWrapper element={element}>
          <LogicJumpEdit element={element} />
          {children}
        </BlockWrapper>
      </Box>
      {isLastJump && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '-32x',
            left: '50%',
            color: 'primary'
          }}
        >
          <Tooltip content={'Add another condition'}>
            <Button
              variant="primaryText"
              color="primaryLight"
              icon={<Icon.Plus />}
              onClick={() => {
                Transforms.insertNodes(editor, Jump.create(), {
                  at: Path.next(elementPath)
                });
              }}
            />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
