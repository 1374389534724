import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { Placeholder } from '../common';

import { Box, Text } from '@churni/styleguide';
import { useIsAnswerMode } from '../../containers';
import { isEmptyNode } from '../../helpers';
import { useEditor, ReactEditor } from 'slate-react';
import { QuestionContext } from './QuestionContext';

/*
 * Renderer for a title block.
 */
export function BlockQuestionTitle(
  props: RenderElementProps
): React.ReactElement {
  const { children, element, attributes } = props;
  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return (
      <BlockQuestionTitleAnswer element={element} attributes={attributes}>
        {children}
      </BlockQuestionTitleAnswer>
    );
  }

  return (
    <BlockQuestionTitleEdit element={element} attributes={attributes}>
      {children}
    </BlockQuestionTitleEdit>
  );
}

export function BlockQuestionTitleAnswer(
  props: RenderElementProps
): React.ReactElement {
  const { children, attributes } = props;
  return (
    <Box
      {...attributes}
      sx={{ position: 'relative', userSelect: 'none' }}
      contentEditable={false}
      color={'primaryText'}
    >
      <Text variant={'heading1'}>
        {children}
        <RequiredAsterisk />
      </Text>
    </Box>
  );
}

export function BlockQuestionTitleEdit(
  props: RenderElementProps
): React.ReactElement {
  const { children, element, attributes } = props;

  const editor = useEditor();
  const path = ReactEditor.findPath(editor, element);
  const isEmpty = isEmptyNode(editor, path);

  return (
    <Box sx={{ position: 'relative' }} {...attributes} color={'primaryText'}>
      {isEmpty && (
        <Placeholder>
          <Text color="secondaryText" variant={'heading1'}>
            Type question here...
          </Text>
        </Placeholder>
      )}

      <Text variant={'heading1'}>
        {children}
        {!isEmpty && <RequiredAsterisk />}
      </Text>
    </Box>
  );
}

export function RequiredAsterisk(props: {}): React.ReactElement {
  const { required } = React.useContext(QuestionContext);

  if (required) {
    return (
      <Text color={'red'} contentEditable={false} sx={{ userSelect: 'none' }}>
        {' '}
        *
      </Text>
    );
  }

  return null;
}
