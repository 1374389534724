import * as React from 'react';
import * as dnd from 'react-dnd';
import { Box } from '@churni/styleguide';
import { Element, Editor, Transforms, Path } from 'slate';
import * as TYPES from '../../../types';
import { ReactEditor } from 'slate-react';
import { DndNodeItem } from './types';
import * as isEqual from 'lodash.isequal';

const basicBlocks = {
  [TYPES.BLOCK_HEADING_1]: true,
  [TYPES.BLOCK_HEADING_2]: true,
  [TYPES.BLOCK_HEADING_3]: true,
  [TYPES.BLOCK_PARAGRAPH]: true,
  [TYPES.BLOCK_LIST_OL]: true,
  [TYPES.BLOCK_LIST_UL]: true
};

const dropRules = {
  [TYPES.BLOCK_PAGE_BODY]: {
    ...basicBlocks,
    [TYPES.BLOCK_CALENDLY]: true,
    [TYPES.BLOCK_QUESTION]: true
  },
  [TYPES.BLOCK_HEADER]: basicBlocks
};

export function DropZone(props: {
  editor: Editor;
  element: Element;
  position: 'before' | 'after';
}): React.ReactElement {
  const { editor, element, position } = props;
  const elementPath = ReactEditor.findPath(editor, element);
  const [parent] = Editor.parent(editor, elementPath);

  const [{ dropHovered, canDrop }, dropRef] = dnd.useDrop({
    accept: 'block',
    canDrop: (item: DndNodeItem) => {
      const isSamePath = isEqual(elementPath, item.path);

      // Cannot drop at the same path
      if (
        isSamePath ||
        (position === 'before' && isEqual(Path.next(item.path), elementPath))
      ) {
        return false;
      }

      // If it's a root block (jump, page), can only drop at the root and not before first page or after last page
      const isRootBlock = item.path.length === 1;

      if (
        item.nodeType === TYPES.BLOCK_JUMP &&
        isRootBlock &&
        (elementPath[0] === 1 ||
          (position === 'after' &&
            elementPath[0] === editor.children.length - 1))
      ) {
        return false;
      }

      if (isRootBlock) {
        return elementPath.length === 1;
      }

      // Otherwise, check if the block can be dropped in the parent
      const parentRules = dropRules[parent.type];

      if (!parentRules) {
        return false;
      }

      return !!parentRules[item.nodeType];
    },
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }

      const droppedPath = elementPath;

      const isSibling = Path.isSibling(droppedPath, item.path);

      const insertPath =
        item.path < droppedPath && position === 'before' && isSibling
          ? Path.previous(droppedPath)
          : droppedPath;

      Transforms.moveNodes(editor, {
        at: item.path,
        to: insertPath
      });
    },
    collect: monitor => {
      return {
        dropHovered: monitor.isOver() && monitor.canDrop(),
        canDrop: monitor.canDrop()
      };
    }
  });

  if (!canDrop) {
    return null;
  }

  return (
    <Box sx={{ zIndex: element.type === TYPES.BLOCK_PAGE ? 0 : 1 }}>
      <Box sx={{ position: 'relative' }}>
        <Box
          ref={dropRef}
          sx={{
            position: 'absolute',
            width: '100%',
            height: 4,
            top: 'auto',
            bottom: '4px',

            bg: 'primary',
            opacity: dropHovered ? 1 : 0,
            zIndex: 1
          }}
        />
      </Box>
    </Box>
  );
}
