import * as React from 'react';

import * as dnd from 'react-dnd';
import { Box, useHover } from '@churni/styleguide';
import * as TYPES from '../../../types';
import { InsertBlock } from './InsertBlock';
import { BlockControls } from './BlockControls';
import { ReactEditor, useEditor } from 'slate-react';
import { DropZone } from './DropZone';
import { Editor, Path, Transforms } from 'slate';
import { useIsAnswerMode } from '../../../containers';
import { DndNodeItem } from './types';

import { getNodesForPath, getInsertActions } from '../../../helpers/queries';
import { Paragraph } from '../../../helpers';
import { Palette } from '../../../containers';

// Check if block is hovered of focused to display block controls
function useBlockHover(element: any) {
  const [hoverChildrenRef, isHoveredChildren] = useHover();
  const [hoverControlsRef, isHoveredControls] = useHover();

  const isHover = isHoveredChildren || isHoveredControls;

  return {
    hoverChildrenRef,
    hoverControlsRef,
    isHover,
    isHoveredChildren,
    isHoveredControls
  };
}

const alignByBlock = {
  [TYPES.BLOCK_PARAGRAPH]: '0px',
  [TYPES.BLOCK_HEADING_1]: '10px',
  [TYPES.BLOCK_HEADING_2]: '6px',
  [TYPES.BLOCK_HEADING_3]: '2px',
  [TYPES.BLOCK_QUESTION]: '8px',
  [TYPES.BLOCK_PAGE]: '24px'
};

/*
 * Wrap a block to add the right margin and controls
 */
export function BlockWrapper(props: {
  children: React.ReactElement;
  element: any;
  controls?: boolean;
  contentEditable?: boolean;
  draggable?: boolean;
}): React.ReactElement {
  const {
    children,
    contentEditable,
    draggable = true,
    controls = true,
    element
  } = props;

  const editor = useEditor();
  const elementPath = ReactEditor.findPath(editor, element);
  const { hoverChildrenRef, hoverControlsRef, isHover } = useBlockHover(
    element
  );

  const dropdownRef = React.useRef();
  const isAnswerMode = useIsAnswerMode();
  const dropdownOpened = dropdownRef.current && dropdownRef.current.opened;

  const isNotEditable =
    isAnswerMode && !contentEditable ? { contentEditable: false } : {};

  const dndItem: DndNodeItem = {
    element,
    path: elementPath,
    type: 'block',
    nodeType: element.type
  };
  const [, draggableRef, previewRef] = dnd.useDrag({
    item: dndItem,
    canDrag: () => {
      return draggable;
    }
  });

  const [parentElement] = Editor.parent(editor, elementPath);
  const isLast = parentElement.children.length === elementPath.slice(-1)[0] + 1;
  const shouldDisplayInsertBlock = ![
    TYPES.BLOCK_PAGE,
    TYPES.BLOCK_PAGE_BODY,
    TYPES.BLOCK_QUESTION_BODY,
    TYPES.BLOCK_JUMP
  ].includes(element.type);

  return (
    <Box
      sx={{ position: 'relative' }}
      className={`${element.type}-${element.id}`}
    >
      <DropZone editor={editor} element={element} position={'before'} />
      <Box sx={{ position: 'relative' }} ref={previewRef}>
        {!isAnswerMode && controls && (
          <Box
            ref={hoverControlsRef}
            contentEditable={false}
            sx={{
              position: 'absolute',
              left: element.type === TYPES.BLOCK_JUMP ? -48 : -23,
              top: alignByBlock[element.type],
              opacity: dropdownOpened || isHover ? 1 : 0,
              transition: 'opacity 0.2s',
              pr: 2,
              userSelect: 'none',
              display: ['none', 'flex'],
              alignItems: 'baseline'
            }}
          >
            <Box ref={draggableRef} sx={{ cursor: 'pointer' }}>
              <BlockControls
                ref={dropdownRef}
                element={element}
                editor={editor}
              />
            </Box>
          </Box>
        )}
        <Box ref={hoverChildrenRef} {...isNotEditable}>
          {children}
        </Box>
        {shouldDisplayInsertBlock && !isAnswerMode && (
          <InsertLine element={element} />
        )}
      </Box>

      {isLast && (
        <DropZone editor={editor} element={element} position={'after'} />
      )}
    </Box>
  );
}

export function InsertLine(props: {
  element: React.ReactElement;
}): React.ReactElement {
  const editor = useEditor();
  const { display } = Palette.useContainer();
  const { element } = props;
  const [insertionLineRef, isHoverInsert] = useHover();

  const onInsertBlock = () => {
    ReactEditor.focus(editor);

    const path = ReactEditor.findPath(editor, element);
    const { node } = getNodesForPath(editor, path);

    const insertPath = Path.next(node[1]);

    Transforms.insertNodes(editor, Paragraph.create(), {
      at: insertPath
    });

    const selectionPath = {
      path: insertPath.concat(0),
      offset: 0
    };

    Transforms.select(editor, {
      focus: selectionPath,
      anchor: selectionPath
    });
    const nodes = getNodesForPath(editor, insertPath);

    display('', getInsertActions(editor, nodes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Box
      ref={insertionLineRef}
      onClick={onInsertBlock}
      sx={{ cursor: 'pointer', py: '4px' }}
    >
      <Box
        sx={{
          position: 'relative',
          height: 2,
          bg: 'border',
          opacity: isHoverInsert ? 1 : 0,
          cursor: 'pointer',
          transition: 'opacity 0.2s'
        }}
      >
        <Box sx={{ position: 'absolute', top: '-8px', left: '-23px' }}>
          <InsertBlock element={element}></InsertBlock>
        </Box>
      </Box>
    </Box>
  );
}
