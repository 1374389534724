import { Element } from 'slate';
import { BLOCK_IMAGE } from '../../types';

/*
 * Create a block image.
 */
export function createImage(data: any): Element {
  return {
    type: BLOCK_IMAGE,
    children: [],
    isVoid: true,
    data
  };
}
