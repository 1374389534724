import { Text, Box } from '@churni/styleguide';
import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { BlockWrapper, Placeholder } from '../common';
import { isEmptyNode } from '../../helpers';
import * as TYPES from '../../types';
import { ReactEditor, useEditor } from 'slate-react';
import { useIsAnswerMode } from '../../containers';

/*
 * Renderer for an heading block.
 */
export function BlockHeading(props: RenderElementProps): React.ReactElement {
  const { children, element, attributes } = props;

  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return (
      <BlockHeadingAnswer
        children={children}
        element={element}
        attributes={attributes}
      />
    );
  }

  return (
    <BlockHeadingEdit
      children={children}
      element={element}
      attributes={attributes}
    />
  );
}

export function BlockHeadingEdit(
  props: RenderElementProps
): React.ReactElement {
  const { children, element, attributes } = props;
  const editor = useEditor();

  const path = ReactEditor.findPath(editor, element);
  const isEmpty = isEmptyNode(editor, path);
  return (
    <Box {...attributes}>
      <BlockWrapper element={element} insertWhenEmpty>
        {isEmpty && (
          <Placeholder>
            <Text color="secondaryText" variant={variants[element.type]}>
              {
                {
                  [TYPES.BLOCK_HEADING_1]: 'Heading 1',
                  [TYPES.BLOCK_HEADING_2]: 'Heading 2',
                  [TYPES.BLOCK_HEADING_3]: 'Heading 3'
                }[element.type]
              }
            </Text>
          </Placeholder>
        )}
        <Text variant={variants[element.type]}>{children}</Text>
      </BlockWrapper>
    </Box>
  );
}

export function BlockHeadingAnswer(
  props: RenderElementProps
): React.ReactElement {
  const { children, element, attributes } = props;

  return (
    <Box {...attributes} contentEditable={false}>
      <Text variant={variants[element.type]}>{children}</Text>
    </Box>
  );
}

const variants = {
  [TYPES.BLOCK_HEADING_1]: 'heading1',
  [TYPES.BLOCK_HEADING_2]: 'heading2',
  [TYPES.BLOCK_HEADING_3]: 'heading3'
};
