import { Editor } from 'slate';
import { BLOCK_PAGE } from '../../../types';

export function getPages(editor: Editor) {
  const nodes = Editor.nodes(editor, {
    at: [],
    match: n => n.type === BLOCK_PAGE
  });

  return Array.from(nodes);
}
