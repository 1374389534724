import { Box, Menu, PortalAbsolute, useClickOutside } from '@churni/styleguide';
import * as React from 'react';
import { Editor } from 'slate';
import { Palette } from '../../containers';
import { insertPaletteAction } from '../../helpers/actions/palette';

export function CommandPalette(props: { editor: Editor }): React.ReactElement {
  const { editor } = props;
  const [position, setPosition] = React.useState<{
    top: number;
    left: number;
  } | null>(null);

  const palette = Palette.useContainer();

  const viewRef = React.useRef();

  const isVisible = palette.visible;

  React.useEffect(() => {
    const nativeSelection = window.getSelection();
    if (nativeSelection.rangeCount < 1) {
      return;
    }
    const range = nativeSelection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    setPosition({
      top: rect.top + window.pageYOffset + rect.height,
      left: rect.left + window.pageXOffset
    });
  }, [isVisible, palette.results.length]);

  if (!isVisible || palette.results.length === 0) {
    return null;
  }

  return (
    <PortalAbsolute>
      <Box
        ref={viewRef}
        sx={{ ...styles.hoverMenu, ...position }}
        className={'command-palette'}
        mb={5}
      >
        <CommandPaletteContent editor={editor} palette={palette} />
      </Box>
    </PortalAbsolute>
  );
}

function CommandPaletteContent(props: {
  editor: Editor;
  palette: PaletteInterface;
}): React.ReactElement {
  const { editor, palette } = props;

  const ref = React.createRef();
  const cursor = palette.cursor;
  const results = palette.results;

  useClickOutside(ref, () => palette.hide());

  return (
    <Box ref={ref} elevation={3} width={250} sx={{ borderRadius: 'rounded' }}>
      <Menu>
        {results.map((command, index) => (
          <Menu.Item
            className={`editor-palette-insert-${command.type}`}
            flex={1}
            active={cursor === index}
            key={command.type}
            icon={<command.icon />}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              insertPaletteAction(editor, palette, command);
            }}
          >
            {command.title}
          </Menu.Item>
        ))}
      </Menu>
    </Box>
  );
}

const styles = {
  hoverMenu: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    borderRadius: 'rounded',
    backgroundColor: 'background'
  },
  formatButton: {
    cursor: 'pointer',
    padding: 1,
    color: 'primary'
  },
  formatButtonActive: {
    color: 'white'
  }
};
