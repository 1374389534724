import { BLOCKS } from '../../../types';
import { Editor, Transforms } from 'slate';
import { genID } from '@churni/core';

export const withJumpSchema = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    // Prevent jumps to be before the first page or at the last position
    if (path.length === 0) {
      const nodeAfterHeader = node.children.length > 1 && node.children[1];
      const lastNode = node.children[node.children.length - 1];

      const isJumpBeforeFirstPage =
        nodeAfterHeader && nodeAfterHeader.type === BLOCKS.BLOCK_JUMP;
      const isJumpAfterLastPage =
        lastNode && lastNode.type === BLOCKS.BLOCK_JUMP;

      if (isJumpBeforeFirstPage) {
        Transforms.removeNodes(editor, {
          at: [1]
        });
      }

      if (isJumpAfterLastPage) {
        Transforms.removeNodes(editor, {
          at: [node.children.length - 1]
        });
      }
    }

    if (path.length === 1 && node.type === BLOCKS.BLOCK_JUMP) {
      // Ensure jump has an id
      if (!node.id) {
        Transforms.setNodes(editor, { id: genID() }, { at: path });
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};
