import {
  palette,
  reference,
  questionTitle,
  list,
  selectionShortcutsHandler,
  heading
} from './handlers';
import { KeyDownProps } from './types';

export const onKeyDown = (props: KeyDownProps) => {
  const handler = new KeyDownHandler(
    [
      palette,
      reference,
      heading,
      questionTitle,
      list,
      selectionShortcutsHandler
    ],
    props
  );

  handler.next();
};

class KeyDownHandler {
  handlers: ((props: KeyDownProps, next: () => void) => void)[];
  cursor: number;
  props: KeyDownProps;
  constructor(
    handlers: ((props: KeyDownProps, next: () => void) => any)[],
    props: KeyDownProps
  ) {
    this.handlers = handlers;
    this.cursor = 0;
    this.props = props;
  }

  next() {
    if (this.cursor >= this.handlers.length) {
      return;
    }

    const currentHandler = this.handlers[this.cursor];
    currentHandler(this.props, () => {
      this.cursor++;
      this.next();
    });
  }
}
