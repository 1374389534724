import { Editor, Path } from 'slate';

export function isEmptyNode(editor: Editor, path: Path): boolean {
  // support also non normalized block that contains multiple texts not merged
  const [node] = Editor.node(editor, path);

  return (
    Editor.string(editor, path) === '' &&
    !editor.isVoid(node) &&
    node.children &&
    node.children.every(n => !editor.isVoid(n))
  );
}
