// Basic blocks
export const BLOCK_PARAGRAPH = 'paragraph';
export const BLOCK_QUESTION = 'question';
export const BLOCK_IMAGE = 'image';
export const BLOCK_HEADER = 'header';
export const BLOCK_PAGE = 'page';
export const BLOCK_JUMP = 'jump';
export const BLOCK_PAGE_BODY = 'page-body';
export const BLOCK_PAGE_HEADER = 'page-header';
export const BLOCK_PAGE_FOOTER = 'page-footer';
export const BLOCK_PAGE_ACTIONS = 'page-actions';
export const BLOCK_PAGE_ACTION_SECONDARY = 'page-action-secondary';
export const BLOCK_PAGE_ACTION_PRIMARY = 'page-action-primary';

export const BLOCK_HEADING_1 = 'heading-1';
export const BLOCK_HEADING_2 = 'heading-2';
export const BLOCK_HEADING_3 = 'heading-3';

export const BLOCK_LIST_UL = 'list-unordered';
export const BLOCK_LIST_OL = 'list-ordered';
export const BLOCK_LIST_ITEM = 'list-item';

// Embeds
export const BLOCK_CALENDLY = 'calendly';

// Questions

export const BLOCK_QUESTION_TITLE = 'question-title';
export const BLOCK_QUESTION_BODY = 'question-body';
export const BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM =
  'question-multiple-choice-item';
export const BLOCK_QUESTION_TEXT_ANSWER = 'question-text-answer';

// Basic marks
export const MARK_BOLD = 'bold';
export const MARK_ITALIC = 'italic';
export const MARK_CODE = 'code';

// Inlines
export const INLINE_LINK = 'link';
export const INLINE_REFERENCE = 'reference';

// For Hyperscript
export const DOCUMENT_BLOCKS = {
  BLOCK_PAGE,
  BLOCK_HEADER,
  BLOCK_JUMP
};

export const TEXT_BLOCKS = {
  BLOCK_HEADING_1,
  BLOCK_HEADING_2,
  BLOCK_HEADING_3,
  BLOCK_PARAGRAPH
};

export const HEADER_BLOCKS = {
  BLOCK_HEADING_1,
  BLOCK_HEADING_2,
  BLOCK_HEADING_3,
  BLOCK_PARAGRAPH,
  BLOCK_LIST_UL,
  BLOCK_LIST_OL,
  BLOCK_LIST_ITEM
};

export const PAGE_BLOCKS = {
  ...TEXT_BLOCKS,
  BLOCK_LIST_UL,
  BLOCK_LIST_OL,
  BLOCK_LIST_ITEM,
  BLOCK_QUESTION,
  BLOCK_CALENDLY
};

export const NESTED_BLOCKS = {
  BLOCK_PAGE_ACTION_SECONDARY,
  BLOCK_PAGE_ACTION_PRIMARY,
  BLOCK_PAGE_HEADER,
  BLOCK_PAGE_BODY,
  BLOCK_PAGE_FOOTER,
  BLOCK_PAGE_ACTIONS,
  BLOCK_QUESTION_TEXT_ANSWER,
  BLOCK_QUESTION_BODY,
  BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM,
  BLOCK_QUESTION_TITLE
};

export const BLOCKS = {
  ...DOCUMENT_BLOCKS,
  ...PAGE_BLOCKS,
  ...NESTED_BLOCKS
};

export const INLINES = {
  INLINE_LINK,
  INLINE_REFERENCE
};

export const MARKS = {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_CODE
};
