import * as React from 'react';
import {
  Box,
  Button,
  Icon,
  Text,
  Dropdown,
  Menu,
  PortalContent,
  Tooltip
} from '@churni/styleguide';
import { Element, Editor, Path, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { getReplaceActions } from '../../../helpers/queries/replace';
import {
  BLOCK_JUMP,
  BLOCK_PARAGRAPH,
  BLOCK_HEADING_1,
  BLOCK_HEADING_2,
  BLOCK_HEADING_3
} from '../../../types';
import { getNodesForPath, getInsertActions } from '../../../helpers';
import { Palette } from '../../../containers';

export const BlockControls = React.forwardRef(function BlockControlsWithRef(
  props: {
    editor: Editor;
    element: Element;
  },
  ref: React.Ref<any>
): React.ReactElement {
  const { element, editor } = props;
  const { display } = Palette.useContainer();

  const [cursor, setCursor] = React.useState(0);

  const portalRef = React.useRef();

  const path = ReactEditor.findPath(editor, element);

  const displayInsert =
    [
      BLOCK_PARAGRAPH,
      BLOCK_HEADING_1,
      BLOCK_HEADING_2,
      BLOCK_HEADING_3
    ].includes(element.type) && !Editor.string(editor, path);

  const onClose = () => {
    if (portalRef.current) {
      portalRef.current.close();
    }
  };

  return (
    <Box ref={ref}>
      <Dropdown
        ref={portalRef}
        position={'south'}
        toggle={
          <Box>
            <Tooltip
              content={
                <Box>
                  <Box>
                    <Text variant={'body2'}>
                      <b>Click</b> to open menu
                    </Text>
                  </Box>
                  <Box>
                    <Text variant={'body2'}>
                      <b>Drag</b> to move
                    </Text>
                  </Box>
                </Box>
              }
            >
              <Button
                className={'editor-grab-button'}
                variant={'text'}
                color={'secondaryText'}
                icon={<Icon.ChevronDown size={'medium'} />}
              />
            </Tooltip>
          </Box>
        }
      >
        <Menu>
          {displayInsert ? (
            <Menu.Item
              icon={<Icon.Plus />}
              onClick={() => {
                ReactEditor.focus(editor);

                const selectionPath = {
                  path: path.concat(0),
                  offset: 0
                };

                Transforms.select(editor, {
                  focus: selectionPath,
                  anchor: selectionPath
                });

                setTimeout(() => {
                  const nodes = getNodesForPath(editor, path);

                  display('', getInsertActions(editor, nodes));
                  portalRef.current.close();
                });
              }}
            >
              Add block
            </Menu.Item>
          ) : (
            <>
              {element.type !== BLOCK_JUMP && (
                <TurnInto
                  close={onClose}
                  path={path}
                  element={element}
                  editor={editor}
                  active={cursor === 0}
                  onMouseEnter={() => {
                    setCursor(0);
                  }}
                />
              )}
            </>
          )}

          <Menu.Item
            className={'editor-remove-block'}
            active={cursor === 1}
            onMouseEnter={() => {
              setCursor(1);
            }}
            onClick={() => {
              Transforms.delete(editor, { at: path });
            }}
            sx={{ py: 0 }}
            icon={<Icon.Trashcan />}
          >
            Delete
          </Menu.Item>
        </Menu>
      </Dropdown>
    </Box>
  );
});

function TurnInto(props: {
  onMouseEnter: () => void;
  close: () => void;
  editor: Editor;
  element: Element;
  active: boolean;
  path: Path;
}): React.ReactElement {
  const { active, editor, onMouseEnter, close, path } = props;

  const [actions, setActions] = React.useState();

  React.useEffect(() => {
    const replaceActions = getReplaceActions(editor, path);
    setActions(Object.values(replaceActions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!actions || actions.length === 0) {
    return null;
  }

  return (
    <>
      <Menu.Item
        onMouseEnter={onMouseEnter}
        active={active}
        sx={{
          py: 0,
          position: 'relative'
        }}
        icon={<Icon.Logout />}
      >
        Turn Into
      </Menu.Item>

      {active && (
        <PortalContent position={'east'} align={'middle'}>
          <Box elevation={3} sx={{ borderRadius: 'rounded', minWidth: 150 }}>
            <Menu>
              {actions.map(action => (
                <Menu.Item
                  className={'editor-turn-into'}
                  key={action.type}
                  icon={<action.icon />}
                  onClick={() => {
                    action.trigger(editor, {
                      node: action.node,
                      parent: action.parent
                    });
                    close();
                    ReactEditor.focus(editor);
                  }}
                >
                  {action.title}
                </Menu.Item>
              ))}
            </Menu>
          </Box>
        </PortalContent>
      )}
    </>
  );
}
