import { createHyperscript } from 'slate-hyperscript';
import { BLOCKS, INLINE_REFERENCE } from './types';

const h = createHyperscript({
  elements: {
    ...Object.values(BLOCKS).reduce((acc, ele) => {
      return { ...acc, [ele]: { type: ele } };
    }, {}),
    [INLINE_REFERENCE]: {
      type: INLINE_REFERENCE
    }
  }
});

export default h;
