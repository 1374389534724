import { Text } from '@churni/styleguide';
import * as React from 'react';
import { RenderLeafProps } from 'slate-react';

export function MarkBold(props: RenderLeafProps): React.ReactElement {
  const { children, attributes } = props;

  return (
    <Text sx={{ fontWeight: 'bold' }} {...attributes}>
      {children}
    </Text>
  );
}
