import { Editor } from 'slate';
import { withDocumentSchema } from './document';
import { withPageSchema } from './page';
import { withQuestionSchema } from './question';
import { withJumpSchema } from './jump';

export function withSchema(editor: Editor) {
  return withQuestionSchema(
    withDocumentSchema(withPageSchema(withJumpSchema(editor)))
  );
}
