import { Box, PortalAbsolute, Flex } from '@churni/styleguide';
import * as React from 'react';
import { Range, Editor } from 'slate';
import { useSlate } from 'slate-react';
import {
  BLOCK_PARAGRAPH,
  BLOCK_HEADING_1,
  BLOCK_HEADING_2,
  BLOCK_HEADING_3,
  BLOCK_PAGE_ACTION_SECONDARY,
  BLOCK_PAGE_ACTION_PRIMARY
} from '../../types';

import { useIsAnswerMode } from '../../containers';
import { TextFormatting } from './TextFormatting';

/*
 * Hover menu to format text.
 */
export function HoveringMenu(): React.ReactElement {
  const editor = useSlate();
  const isAnswerMode = useIsAnswerMode();
  const { selection } = editor;
  useSlate();

  const viewRef = React.useRef();
  const [position, setPosition] = React.useState<{
    top: number;
    left: number;
  } | null>(null);

  React.useEffect(() => {
    if (!selection || !Range.isExpanded(selection)) {
      return;
    }

    const nativeSelection = window.getSelection();
    if (nativeSelection.rangeCount < 1) {
      return;
    }
    const range = nativeSelection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    setPosition({
      top: rect.top + window.pageYOffset - 8,
      left: rect.left + window.pageXOffset + rect.width / 2
    });
  }, [selection]);

  if (!selection) {
    return null;
  }

  const isVisible = Range.isExpanded(selection);

  let node;
  try {
    node = editor.selection
      ? Editor.parent(editor, editor.selection.focus.path)
      : null;
  } catch (err) {
    return null;
  }

  const hoverable = node
    ? {
        [BLOCK_PARAGRAPH]: 'text',
        [BLOCK_HEADING_1]: 'text',
        [BLOCK_HEADING_2]: 'text',
        [BLOCK_HEADING_3]: 'text',
        [BLOCK_PAGE_ACTION_SECONDARY]: 'cta',
        [BLOCK_PAGE_ACTION_PRIMARY]: 'cta'
      }[node[0].type]
    : null;

  if (!isVisible || !position || !hoverable || isAnswerMode) {
    return null;
  }

  // const hasLinks = value.inlines.some(inline => inline.type === 'link');

  return (
    <PortalAbsolute>
      <Box
        sx={{
          position: 'absolute',
          borderRadius: 'rounded',
          transform: 'translate(-50%, -100%)',
          ...position,
          bg: 'background'
        }}
      >
        <Flex ref={viewRef} elevation={3}>
          {{ text: <TextFormatting /> }[hoverable]}
        </Flex>
      </Box>
    </PortalAbsolute>
  );
}
