import * as React from 'react';
import { RenderElementProps } from 'slate-react';

import { Box, Button, usePrimitives, Dropdown } from '@churni/styleguide';
import {
  useIsAnswerMode,
  usePage,
  PagesContext,
  QuestionsContext,
  AnswersContext
} from '../../containers';

import { PageCTA } from '../hovering-menu/PageCTA';

/*
 * Renderer for the next action.
 */
export function BlockButtonSecondary(
  props: RenderElementProps
): React.ReactElement {
  const { children, attributes, element } = props;

  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return (
      <BlockButtonSecondaryAnswer attributes={attributes} element={element}>
        {children}
      </BlockButtonSecondaryAnswer>
    );
  }

  return (
    <BlockButtonSecondaryEdit attributes={attributes} element={element}>
      {children}
    </BlockButtonSecondaryEdit>
  );
}

export function BlockButtonSecondaryEdit(
  props: RenderElementProps
): React.ReactElement {
  const { children, element, attributes } = props;

  const { button } = usePrimitives();

  return (
    <Box {...attributes} ml={[0, 3]} contentEditable={false}>
      <Dropdown
        position={'north'}
        toggle={
          <Box
            sx={{
              ...button.styles,
              ...button.variants['primaryText'],
              zIndex: 500
            }}
          >
            {children}
          </Box>
        }
      >
        <PageCTA element={element} />
      </Dropdown>
    </Box>
  );
}
function BlockButtonSecondaryAnswer(
  props: RenderElementProps
): React.ReactElement {
  const { children, element, attributes } = props;

  const pages = React.useContext(PagesContext);
  const pageQuestions = React.useContext(QuestionsContext);
  const pageAnswers = React.useContext(AnswersContext);

  const { currentPage, next, onCancel, onFinish, setErrors } = usePage();
  const isLastStep = currentPage + 1 === pages.length;

  const onClick = () => {
    const errors = Object.values(pageQuestions).reduce((acc, question) => {
      if (!question.required) {
        return acc;
      }

      if (question.type === 'multiple-choice') {
        if (Object.values(pageAnswers[question.id].answers).length > 0) {
          return acc;
        }
      }

      if (question.type === 'text' && pageAnswers[question.id].answer > 0) {
        return acc;
      }

      return {
        ...acc,
        [question.id]: { error: `Question ${question.title} is required` }
      };
    }, {});

    if (Object.values(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (element.action === 'redirect' && element.redirectURL) {
      if (element.newWindow) {
        window.open(element.redirectURL, '_blank');
      } else {
        window.location.href = element.redirectURL;
      }

      return;
    }

    if (element.action === 'abandon') {
      onCancel(element.status);
      return;
    }

    isLastStep ? onFinish() : next();
  };

  return (
    <Box {...attributes} contentEditable={false} ml={[0, 3]}>
      <Button
        className={'form-next-button'}
        variant={'primaryText'}
        onClick={onClick}
      >
        {children}
      </Button>
    </Box>
  );
}
