import * as React from 'react';
import { RenderElementProps } from 'slate-react';

import { Box, Flex, Switch, Text } from '@churni/styleguide';
import { useIsAnswerMode } from '../../containers';

import { QuestionContext } from './QuestionContext';

/*
 * Renderer for a multiple choice block.
 */
export function BlockQuestionBody(
  props: RenderElementProps
): React.ReactElement {
  const { children, element, attributes } = props;

  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return (
      <Box py={3} {...attributes}>
        {children}
      </Box>
    );
  }

  return (
    <BlockQuestionBodyEdit element={element} attributes={attributes}>
      {children}
    </BlockQuestionBodyEdit>
  );
}

export function BlockQuestionBodyEdit(
  props: RenderElementProps
): React.ReactElement {
  const { children, attributes } = props;

  const { questionType } = React.useContext(QuestionContext);

  return (
    <Box {...attributes}>
      <Flex
        pt={1}
        contentEditable={false}
        sx={{ userSelect: 'none' }}
        alignItems={'center'}
        flexDirection={['column', 'row']}
      >
        {
          {
            'multiple-choice': (
              <Box mr={2}>
                <AllowMultipleAnswers />
              </Box>
            )
          }[questionType]
        }
        <QuestionAnswerRequired />
      </Flex>

      <Box py={3}>{children}</Box>
    </Box>
  );
}

function AllowMultipleAnswers(): React.ReactElement {
  const { multipleAnswers, onMultipleAnswersChange } = React.useContext(
    QuestionContext
  );

  return (
    <Flex alignItems={'center'}>
      <Text variant={'subtitle3'}>Multiple answers</Text>

      <Switch
        ml={1}
        className={'editor-multiple-answers'}
        checked={multipleAnswers}
        onClick={onMultipleAnswersChange}
      />
    </Flex>
  );
}

function QuestionAnswerRequired(): React.ReactElement {
  const { onRequiredChange, required } = React.useContext(QuestionContext);

  return (
    <Flex alignItems={'center'}>
      <Text variant={'subtitle3'}>Required</Text>

      <Switch
        ml={1}
        className={'editor-answer-required'}
        checked={!!required}
        onClick={onRequiredChange}
      />
    </Flex>
  );
}
