import { Editor } from 'slate';

export const getWordAtSelection = (editor: Editor) => {
  const currentFocus = editor.selection.focus;
  const selectionText = Editor.string(editor, currentFocus.path);

  const start = findCharacterBackwardOffset(
    selectionText,
    ' ',
    currentFocus.offset
  );
  const end = findCharacterForwardOffset(
    selectionText,
    ' ',
    currentFocus.offset
  );

  return { word: selectionText.slice(start, end), start, end };
};

const findCharacterBackwardOffset = (
  text: string,
  character: string,
  offset: number
): number => {
  if (offset <= 0) {
    return 0;
  }

  if (text.charAt(offset) !== character) {
    return findCharacterBackwardOffset(text, character, offset - 1);
  }

  return offset;
};

const findCharacterForwardOffset = (
  text: string,
  character: string,
  offset: number
): number => {
  if (offset >= text.length) {
    return text.length;
  }

  if (text.charAt(offset) !== character) {
    return findCharacterForwardOffset(text, character, offset + 1);
  }

  return offset;
};
