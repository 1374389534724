/* eslint-disable no-undef */
import * as React from 'react';
import Helmet from 'react-helmet';
import { RenderElementProps } from 'slate-react';
import { Element } from 'slate';
import { BlockWrapper } from '../common';
import { Box, Flex } from '@churni/styleguide';
import { useIsAnswerMode } from '../../containers';

/*
 * Renderer for an calendly block.
 */
export function BlockCalendly(props: RenderElementProps): React.ReactElement {
  const { element, attributes } = props;
  const timeout = React.useRef();
  const isAnswerMode = useIsAnswerMode();

  const initCalendly = () => {
    if (window.Calendly) {
      clearTimeout(timeout.current);
      window.Calendly.initInlineWidget({
        url: `${element.url}`,
        parentElement: document.getElementById('calendly-inline-widget')
      });
    } else {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        initCalendly();
      }, 50);
    }
  };

  React.useEffect(() => {
    initCalendly();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
        ></script>
      </Helmet>

      <Box
        {...attributes}
        contentEditable={false}
        sx={{ userSelect: 'none', maxWidth: 640, mx: 'auto' }}
      >
        {isAnswerMode ? (
          <BlockCalendlyAnswer />
        ) : (
          <BlockCalendlyEdit element={element} />
        )}
      </Box>
    </>
  );
}

export function BlockCalendlyAnswer(props: {}): React.ReactElement {
  return (
    <Flex
      justifyContent={'center'}
      contentEditable={false}
      sx={{ userSelect: 'none' }}
    >
      <Box
        contentEditable={false}
        flex={1}
        id="calendly-inline-widget"
        sx={{
          maxWidth: 640,
          height: 770,
          userSelect: 'none'
        }}
        data-auto-load="false"
      ></Box>
    </Flex>
  );
}

export function BlockCalendlyEdit(props: {
  element: Element;
}): React.ReactElement {
  const { element } = props;
  return (
    <BlockWrapper element={element}>
      <BlockCalendlyAnswer />
    </BlockWrapper>
  );
}
