import { Transforms, Editor, Path, Node } from 'slate';
import {
  Embeds,
  Heading,
  Image,
  List,
  Question,
  Page,
  Paragraph,
  Jump
} from '../../helpers';

interface Context {
  parent: Node;
  node: Node;
}

export const withInsertBlockCommands = (editor: Editor) => {
  editor.insertCalendly = (context: Context) => {
    const url = window.prompt(
      'Enter the URL of your calendly (https://calendly.com/example/30min):'
    );

    if (url == null) {
      return;
    }

    return insertBlock(editor, context, Embeds.createCalendly(url), false);
  };

  editor.insertParagraph = (context: Context) => {
    return insertBlock(editor, context, Paragraph.create());
  };

  editor.insertH1 = (context: Context) => {
    return insertBlock(editor, context, Heading.createH1());
  };

  editor.insertH2 = (context: Context) => {
    return insertBlock(editor, context, Heading.createH2());
  };

  editor.insertH3 = (context: Context) => {
    return insertBlock(editor, context, Heading.createH3());
  };

  editor.insertImage = (context: Context) => {
    return insertBlock(editor, context, Image.createImage({}));
  };

  editor.insertListOrdered = (context: Context) => {
    return insertBlock(editor, context, List.createList({ ordered: true }), [
      0,
      0
    ]);
  };

  editor.insertListUnordered = (context: Context) => {
    return insertBlock(editor, context, List.createList({ ordered: false }), [
      0,
      0
    ]);
  };

  editor.insertMultipleChoiceQuestion = (context: Context) => {
    return insertBlock(
      editor,
      context,
      Question.createMultipleChoiceQuestion(),
      [0, 0]
    );
  };

  editor.insertTextQuestion = (context: Context) => {
    return insertBlock(editor, context, Question.createTextQuestion(), [0, 0]);
  };

  editor.insertJump = (context: Context) => {
    const { node } = context;
    const newPagePath = Path.next([node[1][0]]);

    Transforms.insertNodes(editor, Jump.create(), {
      at: newPagePath
    });
  };

  editor.insertPage = (context: Context, newPage = Page.create()) => {
    const { node } = context;
    const newPagePath = Path.next([node[1][0]]);

    Transforms.insertNodes(editor, newPage, {
      at: newPagePath
    });

    const bodyNode = Editor.node(editor, newPagePath.concat([0, 0]));

    const selectionPath =
      bodyNode[0].type === 'question'
        ? bodyNode[1].concat([0, 0])
        : bodyNode[1].concat([0]);

    Transforms.select(editor, {
      anchor: {
        path: selectionPath,
        offset: 0
      },
      focus: {
        path: selectionPath,
        offset: 0
      }
    });
    return newPagePath;
  };

  return editor;
};

const insertBlock = (
  editor: Editor,
  context: Context,
  newElement: Element,
  focusPath = [0]
) => {
  const { node } = context;
  const replace = !Editor.string(editor, node[1]);

  if (replace) {
    Transforms.removeNodes(editor, { at: node[1] });
    Transforms.insertNodes(editor, newElement, {
      at: node[1]
    });
  } else {
    Transforms.insertNodes(editor, newElement, {
      at: node[1]
    });
  }
  if (focusPath) {
    Transforms.select(editor, {
      anchor: { path: node[1].concat(focusPath).flat(), offset: 0 },
      focus: { path: node[1].concat(focusPath).flat(), offset: 0 }
    });
  }
};
