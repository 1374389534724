/** @jsx h */
import { Page, Question } from '../helpers';
import h from '../hyperscript';
import { Icon } from '@churni/styleguide';
import { genID } from '@churni/core';

export const retain = {
  name: 'Retain customers',
  description: 'Template to retain customers depending on feedback',
  create,
  Icon: Icon.Paragraph
};

function create(companyName = 'Pied Piper') {
  const pageIDFeatures = genID();
  const pageIDLast = genID();
  const pageIDHelp = genID();
  const questionID = genID();
  const answerTooExpensive = genID();
  const answerMissingFeatures = genID();
  const answerSupport = genID();
  const answerDifficultSetup = genID();
  const pageDiscountID = genID();

  return (
    <editor>
      <header>
        <heading-1>{companyName}</heading-1>
        <paragraph>
          To process your cancellation, please follow the instructions below.
        </paragraph>
      </header>

      <page id={pageIDHelp}>
        <page-body>
          <heading-1>
            We want to help. Would you like to talk with a {companyName} team
            member first?
          </heading-1>
          <paragraph>
            Select a time below from our team calendar. Or, if you need
            immediate help, chat with us.
          </paragraph>
          <paragraph>{`<<< INSERT CALENDLY WIDGET >>>`}</paragraph>
        </page-body>
        <page-actions>
          <page-action-secondary action="continue">
            I want to cancel now
          </page-action-secondary>
          <page-action-primary action="abandon" status="call_scheduled">
            I scheduled a time
          </page-action-primary>
        </page-actions>
      </page>
      <page>
        <page-body>
          <question
            questionType={'multiple-choice'}
            id={questionID}
            required={true}
          >
            {Question.createTitle("What's your primary reason for canceling?")}
            <question-body>
              {Question.createMultipleChoiceItem(`Don't use it enough`)}
              <question-multiple-choice-item id={answerMissingFeatures}>
                <text>Missing features</text>
              </question-multiple-choice-item>
              <question-multiple-choice-item id={answerTooExpensive}>
                <text>Too expensive</text>
              </question-multiple-choice-item>
              <question-multiple-choice-item id={answerSupport}>
                <text>Need more support</text>
              </question-multiple-choice-item>
              {Question.createMultipleChoiceItem(`Found another solution`)}
              <question-multiple-choice-item id={answerDifficultSetup}>
                <text>Difficult to setup / Too complex</text>
              </question-multiple-choice-item>
              {Question.createMultipleChoiceItem(`Other`)}
            </question-body>
          </question>
        </page-body>
        {Page.createPageActions()}
      </page>

      <jump
        condition={'question'}
        questionID={questionID}
        answerID={answerMissingFeatures}
        pageID={pageIDFeatures}
      >
        <text />
      </jump>
      <jump
        condition={'question'}
        questionID={questionID}
        answerID={answerTooExpensive}
        pageID={pageDiscountID}
      >
        <text />
      </jump>
      <jump condition={'always'} pageID={pageIDLast}>
        <text />
      </jump>
      <page id={pageIDFeatures}>
        <page-body>
          <heading-1>
            Would you like to check out some of these new features we're about
            to release?
          </heading-1>

          <paragraph>
            If you have a feature request, chat with us, we can probably give
            you some information/help about it.
          </paragraph>
        </page-body>
        <page-actions>
          <page-action-secondary action="continue">
            No, I want to cancel now
          </page-action-secondary>
          <page-action-primary
            action="redirect"
            newWindow={true}
            redirectURL="https://www.example.com/roadmap"
          >
            Yes, I do
          </page-action-primary>
        </page-actions>
      </page>
      <jump condition={'always'} pageID={pageIDLast}>
        <text />
      </jump>

      <page id={pageDiscountID}>
        <page-body>
          <heading-1>
            Would you be interested in a 30% coupon for the next 3 months?
          </heading-1>
          <paragraph>Try it out, save some money!</paragraph>
        </page-body>
        <page-actions>
          <page-action-secondary action="continue">
            No, I want to cancel now
          </page-action-secondary>
          <page-action-primary action="abandon" status="offer_accepted">
            Yes, I do
          </page-action-primary>
        </page-actions>
      </page>
      <jump condition={'always'} pageID={pageIDLast}>
        <text />
      </jump>

      <page id={pageIDLast}>
        <page-body>
          <question required={true} questionType={'text'}>
            {Question.createTitle(
              'We would love to know what we can do to make this product better.'
            )}
            <question-body>{Question.createQuestionTextAnswer()}</question-body>
          </question>
        </page-body>
        <page-actions>
          <page-action-primary action="continue">
            Cancel my subscription
          </page-action-primary>
        </page-actions>
      </page>
    </editor>
  );
}
