import { Transforms, Editor } from 'slate';
import * as TYPES from '../../types';

interface Context {
  parent: Node;
  node: Node[];
}

export const withReplaceBlockCommands = (editor: Editor) => {
  editor.replaceH1 = (context: Context) => {
    return replaceBlock(editor, context, {
      ...context.node[0],
      type: TYPES.BLOCK_HEADING_1
    });
  };

  editor.replaceH2 = (context: Context) => {
    return replaceBlock(editor, context, {
      ...context.node[0],
      type: TYPES.BLOCK_HEADING_2
    });
  };

  editor.replaceH3 = (context: Context) => {
    return replaceBlock(editor, context, {
      ...context.node[0],
      type: TYPES.BLOCK_HEADING_3
    });
  };

  editor.replaceParagraph = (context: Context) => {
    return replaceBlock(editor, context, {
      ...context.node[0],
      type: TYPES.BLOCK_PARAGRAPH
    });
  };

  return editor;
};

const replaceBlock = (
  editor: Editor,
  context: Context,
  newElement: Element
) => {
  const { node } = context;

  const nodePath = node[1];
  Transforms.setNodes(editor, newElement, {
    at: nodePath
  });

  Transforms.select(editor, {
    anchor: { path: nodePath.concat(0), offset: 0 },
    focus: { path: nodePath.concat(0), offset: 0 }
  });
};
