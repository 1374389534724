import * as React from 'react';

import { InlineCard } from '@churni/styleguide';
import { RenderElementProps } from 'slate-react';
import { useIsAnswerMode, VariablesContext } from '../../containers';
import { Box } from '@churni/styleguide';

/*
 * Renderer for a inline reference.
 */
export function InlineReference(props: RenderElementProps): React.ReactElement {
  const { element, children, attributes } = props;

  const isAnswerMode = useIsAnswerMode();

  const variables = React.useContext(VariablesContext);
  const currentReference = variables[element.slug];

  if (isAnswerMode) {
    if (!currentReference) {
      return null;
    }

    return (
      <Box as={'span'} {...attributes}>
        {currentReference}
      </Box>
    );
  }

  return (
    <InlineCard
      elevation={null}
      {...attributes}
      sx={{
        color: 'textOnPrimary',
        bg: 'primary'
      }}
    >
      {element.slug}
      {children}
    </InlineCard>
  );
}
