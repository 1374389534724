import * as React from 'react';
import { RenderElementProps, useEditor } from 'slate-react';
import { Box, Text, Flex, Link, Icon, useHover } from '@churni/styleguide';
import {
  useIsAnswerMode,
  usePage,
  PagesContext,
  JumpsContext
} from '../../containers';
import { ReactEditor } from 'slate-react';
import { BlockWrapper } from '../common/BlockWrapper';
import { Editor, Transforms, Path } from 'slate';
import { BLOCK_JUMP } from '../../types';
import { Jump } from '../../helpers';

/*
 * Renderer for a page block.
 */
export function BlockPage(props: RenderElementProps): React.ReactElement {
  const { children, element, attributes } = props;

  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return (
      <BlockPageAnswer attributes={attributes} element={element}>
        {children}
      </BlockPageAnswer>
    );
  }

  return (
    <BlockPageEdit attributes={attributes} element={element}>
      {children}
    </BlockPageEdit>
  );
}

export function BlockPageAnswer(props: RenderElementProps): React.ReactElement {
  const { attributes, element, children } = props;
  const pages = React.useContext(PagesContext);
  const { currentPage: page, onCancel } = usePage();
  const isCurrentPage = pages[page][0].id === element.id;

  if (!isCurrentPage) {
    return null;
  }

  return (
    <Box {...attributes}>
      <Box elevation={1} py={3} px={3} my={3} sx={{ userSelect: 'none' }}>
        {children}
      </Box>
      <Flex
        justifyContent="center"
        contentEditable={false}
        sx={{ cursor: 'pointer', userSelect: 'none' }}
      >
        <Link
          variant="primary"
          as={'span'}
          onClick={() => onCancel('footer_link')}
        >
          <Text variant="subtitle3" icon={<Icon.ArrowLeft />}>
            {' '}
            Nevermind, take me back to my account
          </Text>
        </Link>
      </Flex>
    </Box>
  );
}

export function BlockPageEdit(props: RenderElementProps): React.ReactElement {
  const { attributes, element, children } = props;
  const editor = useEditor();
  const path = ReactEditor.findPath(editor, element);
  const pages = React.useContext(PagesContext);
  React.useContext(JumpsContext);

  const [ref, isHover] = useHover();

  const pageIndex = pages.findIndex(p => p[1][0] === path[0]);

  const previousNode = Editor.node(editor, Path.previous(path));
  const displayInsert = previousNode[0].type !== BLOCK_JUMP && pageIndex > 0;

  return (
    <Box mb={2} {...attributes}>
      {displayInsert && (
        <Flex
          contentEditable={false}
          ref={ref}
          justifyContent={'center'}
          sx={{
            userSelect: 'none',
            color: isHover ? 'text' : 'secondaryText'
          }}
        >
          <Text
            icon={<Icon.CornerDownRight />}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              Transforms.insertNodes(editor, Jump.create(), {
                at: path
              })
            }
            variant="body3"
          >
            Insert a logic jump
          </Text>
        </Flex>
      )}
      <Box mt={displayInsert ? 2 : 0}>
        <BlockWrapper element={element} contentEditable>
          <Box elevation={3} py={3} px={3} className="container">
            <Box contentEditable={false} sx={{ userSelect: 'none' }} pb={1}>
              <Text variant={'overline'} color="secondaryText">
                Page {pageIndex + 1}
              </Text>
            </Box>
            <Box>{children}</Box>
          </Box>
        </BlockWrapper>
      </Box>
    </Box>
  );
}
