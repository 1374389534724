import { Editor } from 'slate';
import { getNodesForPath } from '../common/getNodesForPath';
import { Nodes } from '../types';

export const getNodesForSelection = (editor: Editor): Nodes => {
  const currentPath = editor.selection.focus.path;

  const [, path] = Editor.parent(editor, currentPath);

  return getNodesForPath(editor, path);
};
