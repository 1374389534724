import * as React from 'react';
import {
  Box,
  Flex,
  Text,
  Menu,
  Icon,
  Button,
  Input,
  Select,
  Tooltip,
  Switch
} from '@churni/styleguide';
import { useEditor, ReactEditor } from 'slate-react';
import { Editor, Transforms } from 'slate';
import * as slugify from 'slugify';
import { BLOCK_PAGE_ACTION_SECONDARY } from '../../types';

export function PageCTA(props: { element: Element }): React.ReactElement {
  const { element } = props;

  const editor = useEditor();
  const elementPath = ReactEditor.findPath(editor, element);
  const isLastPage = elementPath[0] === editor.children.length - 1;

  const secondaryCTA = element.type === BLOCK_PAGE_ACTION_SECONDARY;

  const action = element.action;
  const newWindow = element.newWindow || false;
  const redirectURL = element.redirectURL || '';
  const status = element.status || '';

  const onChangeAction = (e: React.SyntheticEvent) => {
    Transforms.setNodes(
      editor,
      { action: e.currentTarget.value },
      { at: elementPath }
    );
  };

  const onChangeRedirect = (e: React.SyntheticEvent) => {
    Transforms.setNodes(
      editor,
      { redirectURL: e.currentTarget.value },
      { at: elementPath }
    );
  };

  const onChangeAbandonStatus = (e: React.SyntheticEvent) => {
    Transforms.setNodes(
      editor,
      { status: slugify(e.currentTarget.value) },
      { at: elementPath }
    );
  };

  const onClickNewWindow = (e: React.SyntheticEvent) => {
    Transforms.setNodes(editor, { newWindow: !newWindow }, { at: elementPath });
  };

  const textNode = Editor.node(editor, elementPath.concat(0));

  const onChangeLabel = (e: React.SyntheticEvent) => {
    Transforms.insertText(editor, e.currentTarget.value, { at: textNode[1] });
  };

  return (
    <Menu sx={{ minWidth: 300, position: 'relative', userSelect: 'none' }}>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        px={2}
        py={1}
      >
        {secondaryCTA ? (
          <>
            <Box>
              <Text variant="overline" color={'secondaryText'}>
                Secondary button
              </Text>
            </Box>
            <Button
              ml={2}
              variant="text"
              icon={<Icon.Trashcan />}
              onClick={() => {
                Transforms.delete(editor, { at: elementPath });
              }}
            />
          </>
        ) : (
          <Text variant="overline" color={'secondaryText'}>
            Primary button
          </Text>
        )}
      </Flex>
      <Box p={2}>
        <Flex alignItems="center" justifyContent="space-between" mb={1}>
          <Text variant={'body2'}>CTA</Text>
          <Input
            focus
            ml={3}
            sx={{ width: 200 }}
            onChange={onChangeLabel}
            value={textNode[0].text}
          ></Input>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mb={1}>
          <Text variant={'body2'}>Action</Text>
          <Select
            ml={3}
            sx={{ width: 200 }}
            value={action}
            onChange={onChangeAction}
          >
            <option value={'abandon'}>Redirect back to website</option>
            <option value={'continue'}>
              {isLastPage ? `Complete Survey` : `Continue to next page`}
            </option>
            <option value={'redirect'}>Redirect to custom URL</option>
          </Select>
        </Flex>
        {action === 'redirect' && (
          <Box>
            <Flex alignItems="center" justifyContent="space-between" mb={1}>
              <Text variant={'body2'}>URL</Text>
              <Input
                ml={3}
                sx={{ width: 200 }}
                placeholder={'https://www.example.com'}
                value={redirectURL}
                onChange={onChangeRedirect}
              ></Input>
            </Flex>
            <Flex alignItems="center" mt={1}>
              <Text variant={'body2'}>Open in a new window</Text>
              <Switch onClick={onClickNewWindow} checked={newWindow} ml={1} />
            </Flex>
          </Box>
        )}
        {action === 'abandon' && (
          <Flex alignItems="center" justifyContent="space-between" mb={1}>
            <Flex alignItems="center">
              <Text variant={'body2'}>Reason</Text>
              <Tooltip
                content={
                  <Box>
                    <Box>
                      Pass a reason when redirecting back to your website to
                      apply potential offers, discounts.
                    </Box>
                    <Box>
                      (ie: https://app.example.com/save?reason=offer_accepted)
                    </Box>
                    <Box mt={1}>
                      It will also be stored in the session if you use webhooks
                      or zapier
                    </Box>
                  </Box>
                }
              >
                <Box>
                  <Icon.Info ml={1} />
                </Box>
              </Tooltip>
            </Flex>

            <Input
              ml={3}
              sx={{ width: 200 }}
              value={status}
              placeholder="offer_accepted"
              onChange={onChangeAbandonStatus}
            ></Input>
          </Flex>
        )}
      </Box>
    </Menu>
  );
}
