import * as React from 'react';
import { RenderElementProps, ReactEditor, useEditor } from 'slate-react';

import { Box, Text } from '@churni/styleguide';
import { BlockWrapper } from '../common/BlockWrapper';

import { EmptyParagraphPlaceholder } from './EmptyParagraphPlaceholder';
import { Editor } from 'slate';
import { useIsAnswerMode } from '../../containers';
import { BLOCKS } from '../../types';

/*
 * Renderer for a paragraph block.
 */
export function BlockParagraph(props: RenderElementProps): React.ReactElement {
  const { children, element, attributes } = props;

  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return (
      <BlockParagraphAnswer attributes={attributes} element={element}>
        {children}
      </BlockParagraphAnswer>
    );
  }

  return (
    <BlockParagraphEdit attributes={attributes} element={element}>
      {children}
    </BlockParagraphEdit>
  );
}

export function BlockParagraphEdit(
  props: RenderElementProps
): React.ReactElement {
  const { children, element, attributes } = props;

  const editor = useEditor();
  const elementPath = ReactEditor.findPath(editor, element);
  const parent = Editor.parent(editor, elementPath);

  const editable = parent[0].type === 'question-text-answer';

  return (
    <Box {...attributes}>
      <BlockWrapper
        element={element}
        contentEditable={editable}
        insertWhenEmpty
      >
        <Box sx={{ position: 'relative' }}>
          <EmptyParagraphPlaceholder element={element} />
          <Text>{children}</Text>
        </Box>
      </BlockWrapper>
    </Box>
  );
}

export function BlockParagraphAnswer(
  props: RenderElementProps
): React.ReactElement {
  const { attributes, element, children } = props;
  const editor = useEditor();

  const path = ReactEditor.findPath(editor, element);
  const [parent] = Editor.parent(editor, path);

  const contentEditable =
    parent.type === BLOCKS.BLOCK_QUESTION_TEXT_ANSWER
      ? {}
      : { contentEditable: false };

  return (
    <Box {...attributes} {...contentEditable}>
      <Text>{children}</Text>
    </Box>
  );
}
