import { Box } from '@churni/styleguide';
import * as React from 'react';
import { SxStyleProp } from 'rebass';

const STYLES = {
  placeholder: {
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%'
  }
};

/*
 * Component to use to render a placeholder in the Slate editor.
 */
export function Placeholder(props: {
  children: React.ReactElement;
  sx?: SxStyleProp;
}) {
  const { children, sx = {} } = props;

  return (
    <Box
      sx={{ userSelect: 'none', ...STYLES.placeholder, ...sx }}
      contentEditable={false}
    >
      {children}
    </Box>
  );
}
