import { KeyDownProps } from '../types';
import { Transforms, Editor } from 'slate';
import {
  BLOCK_HEADING_1,
  BLOCK_HEADING_2,
  BLOCK_HEADING_3,
  BLOCK_PARAGRAPH
} from '../../../types';
import { Paragraph, isEmptyNode } from '../../../helpers';

export const heading = (props: KeyDownProps, next: () => void) => {
  const { editor, event } = props;

  if (event.key !== 'Enter') {
    return next();
  }

  const [startBlock, path] = Editor.parent(editor, editor.selection.focus.path);

  const handledBlocks = [BLOCK_HEADING_1, BLOCK_HEADING_2, BLOCK_HEADING_3];

  if (!handledBlocks.includes(startBlock.type)) {
    return next();
  }

  event.preventDefault();
  const replace = isEmptyNode(editor, path);

  if (replace) {
    Transforms.setNodes(editor, { type: BLOCK_PARAGRAPH });
    return;
  }

  Transforms.insertNodes(editor, Paragraph.create());
};
