import * as React from 'react';
import { useEditor, ReactEditor } from 'slate-react';
import { Text } from '@churni/styleguide';
import { Placeholder } from '../common/Placeholder';
import { isActiveNode, isEmptyNode } from '../../helpers';
import { Palette, useIsAnswerMode } from '../../containers';

export function EmptyParagraphPlaceholder(props: {
  element: Element;
}): React.ReactElement {
  const { element } = props;
  const editor = useEditor();
  const palette = Palette.useContainer();
  const path = ReactEditor.findPath(editor, element);
  const isAnswerMode = useIsAnswerMode();

  const isEmptyActive = isEmptyNode(editor, path) && isActiveNode(editor, path);

  if (isAnswerMode || !isEmptyActive || palette.visible) {
    return null;
  }

  return (
    <Placeholder>
      <Text
        color={'secondaryText'}
      >{`Type '/' for commands or '@' to insert variable references`}</Text>
    </Placeholder>
  );
}
