import { Box, Menu, PortalAbsolute, useClickOutside } from '@churni/styleguide';
import * as React from 'react';
import { Editor } from 'slate';
import { Reference } from '../../containers';
import { insertReference } from '../../helpers';

export function ReferencePicker(props: { editor: Editor }): React.ReactElement {
  const { editor } = props;
  const [position, setPosition] = React.useState<{
    top: number;
    left: number;
  } | null>(null);
  const reference = Reference.useContainer();

  const viewRef = React.useRef();

  const isVisible = reference.visible;

  React.useEffect(() => {
    const nativeSelection = window.getSelection();
    if (nativeSelection.rangeCount < 1) {
      return;
    }
    const range = nativeSelection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    setPosition({
      top: rect.top + window.pageYOffset + rect.height,
      left: rect.left + window.pageXOffset
    });
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <PortalAbsolute>
      <Box ref={viewRef} sx={{ ...styles.hoverMenu, ...position }}>
        <CommandPaletteContent editor={editor} reference={reference} />
      </Box>
    </PortalAbsolute>
  );
}

function CommandPaletteContent(props: {
  editor: Editor;
  reference: ReferenceInterface;
}): React.ReactElement {
  const { editor, reference } = props;

  const ref = React.createRef();
  const cursor = reference.cursor;
  const results = reference.results;

  useClickOutside(ref, () => reference.hide());

  if (!results.length) {
    return null;
  }

  return (
    <Box ref={ref} elevation={3} width={250}>
      <Menu>
        {results.map((result, index) => (
          <Menu.Item
            className={'editor-insert-reference'}
            onClick={() => {
              insertReference(editor, reference, result);
            }}
            flex={1}
            active={cursor === index}
            key={result.key}
          >
            {result.title}
          </Menu.Item>
        ))}
      </Menu>
    </Box>
  );
}

const styles = {
  hoverMenu: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    borderRadius: 'rounded',
    backgroundColor: 'background'
  },
  formatButton: {
    cursor: 'pointer',
    padding: 1,
    color: 'primary'
  },
  formatButtonActive: {
    color: 'white'
  }
};
