import { Editor, Path, NodeEntry, Element } from 'slate';
import { BLOCKS, Action } from '../../../blocks';
import { getNodesForPath } from '../common';

export interface ReplaceAction {
  node: NodeEntry;
  parent: NodeEntry;
  type: string;
  title: string;
  icon: string;
  trigger: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => void;
}

export function getReplaceActions(editor: Editor, path: Path): ReplaceAction[] {
  const { node, parent } = getNodesForPath(editor, path);
  const blockActions = BLOCKS[node[0].type];

  return Object.entries(blockActions.replace).map(mapReplace(node, parent));
}

const mapReplace = (node: NodeEntry, parent: NodeEntry) => ([key, value]: [
  string,
  Action
]): ReplaceAction => {
  const blockToInsert = BLOCKS[key];

  return {
    node,
    parent,
    title: blockToInsert.title,
    icon: blockToInsert.icon,
    type: key,
    trigger: value
  };
};
