import { KeyDownProps } from '../types';
import { Editor, Transforms, Path, Text } from 'slate';
import {
  BLOCK_LIST_ITEM,
  BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM
} from '../../../types';
import { Paragraph } from '../../../helpers';

export const list = (props: KeyDownProps, next: () => void) => {
  const { editor, event } = props;

  if (event.key !== 'Enter') {
    return next();
  }

  const [startBlock, path] = Editor.parent(editor, editor.selection.focus.path);

  const handledBlocks = [BLOCK_LIST_ITEM, BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM];

  if (!handledBlocks.includes(startBlock.type)) {
    return next();
  }

  const itemText = Editor.string(editor, path);

  if (itemText !== '') {
    return next();
  }
  event.preventDefault();
  const [, listPath] = Editor.parent(editor, path);

  if (startBlock.type === BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM) {
    const parentPath = Path.parent(listPath);

    Transforms.insertNodes(editor, Paragraph.create(), {
      at: Path.next(parentPath)
    });

    Transforms.removeNodes(editor, { at: path });
    const [, nextTextPath] = Editor.next(editor, {
      at: parentPath,
      match: n => Text.isText(n)
    });
    Transforms.select(editor, {
      anchor: {
        path: nextTextPath,
        offset: 0
      },
      focus: { path: nextTextPath, offset: 0 }
    });
  } else {
    Transforms.insertNodes(editor, Paragraph.create(), {
      at: Path.next(listPath)
    });
    Transforms.removeNodes(editor, { at: path });
    const [, nextTextPath] = Editor.next(editor, {
      at: listPath,
      match: n => Text.isText(n)
    });
    Transforms.select(editor, {
      anchor: {
        path: nextTextPath,
        offset: 0
      },
      focus: { path: nextTextPath, offset: 0 }
    });
  }
};
