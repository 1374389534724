/** @jsx h */
import { Element } from 'slate';
import h from '../../hyperscript';
import * as Paragraph from './paragraph';

export function create(): Element {
  return (
    <page>
      {createBody()}
      {createPageActions()}
    </page>
  );
}

export function createBody(): Element {
  return <page-body>{Paragraph.create()}</page-body>;
}

export function createPageActions(): Element {
  return <page-actions>{createPageActionPrimary()}</page-actions>;
}

export function createPageActionPrimary(): Element {
  return (
    <page-action-primary action="continue">
      <text>Continue</text>
    </page-action-primary>
  );
}

export function createPageActionSecondary(): Element {
  return (
    <page-action-secondary action="abandon">
      <text>Secondary CTA</text>
    </page-action-secondary>
  );
}
