import { RenderElementProps } from 'slate-react';
import * as React from 'react';
import * as TYPES from './types';

import {
  BlockCalendly,
  BlockParagraph,
  BlockHeading,
  BlockPage,
  BlockPageBody,
  BlockJump,
  BlockQuestion,
  BlockQuestionTitle,
  BlockQuestionTextAnswer,
  BlockQuestionBody,
  BlockQuestionMultipleChoiceItem,
  BlockList,
  BlockListItem,
  BlockHeader,
  BlockPageActions,
  BlockButtonPrimary,
  BlockButtonSecondary,
  InlineReference,
  InlineLink
} from './components';

export function renderElement(props: RenderElementProps): React.ReactElement {
  switch (props.element.type) {
    case TYPES.BLOCK_PARAGRAPH:
      return <BlockParagraph {...props} />;
    case TYPES.BLOCK_CALENDLY:
      return <BlockCalendly {...props} />;
    case TYPES.BLOCK_HEADER:
      return <BlockHeader {...props} />;
    case TYPES.BLOCK_HEADING_1:
    case TYPES.BLOCK_HEADING_2:
    case TYPES.BLOCK_HEADING_3:
      return <BlockHeading {...props} />;
    case TYPES.BLOCK_LIST_UL:
    case TYPES.BLOCK_LIST_OL:
      return <BlockList {...props} />;
    case TYPES.BLOCK_LIST_ITEM:
      return <BlockListItem {...props} />;
    case TYPES.BLOCK_PAGE:
      return <BlockPage {...props} />;
    case TYPES.BLOCK_QUESTION:
      return <BlockQuestion {...props} />;
    case TYPES.BLOCK_QUESTION_TITLE:
      return <BlockQuestionTitle {...props} />;
    case TYPES.BLOCK_QUESTION_TEXT_ANSWER:
      return <BlockQuestionTextAnswer {...props} />;
    case TYPES.BLOCK_QUESTION_BODY:
      return <BlockQuestionBody {...props} />;
    case TYPES.BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM:
      return <BlockQuestionMultipleChoiceItem {...props} />;
    case TYPES.BLOCK_PAGE_BODY:
      return <BlockPageBody {...props} />;
    case TYPES.BLOCK_PAGE_ACTIONS:
      return <BlockPageActions {...props} />;
    case TYPES.BLOCK_PAGE_ACTION_PRIMARY:
      return <BlockButtonPrimary {...props} />;
    case TYPES.BLOCK_PAGE_ACTION_SECONDARY:
      return <BlockButtonSecondary {...props} />;
    case TYPES.BLOCK_JUMP:
      return <BlockJump {...props} />;
    case TYPES.INLINE_REFERENCE:
      return <InlineReference {...props} />;
    case TYPES.INLINE_LINK:
      return <InlineLink {...props} />;

    default:
      return null;
  }
}
