import { Editor, Element, NodeEntry } from 'slate';
import { Action, BLOCKS } from '../../../blocks';
import { Nodes } from '../types';

export interface InsertAction {
  node: NodeEntry;
  parent: NodeEntry;
  type: string;
  title: string;
  icon: string;
  trigger: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => void;
}

export function getInsertActions(
  editor: Editor,
  params: Nodes
): InsertAction[] {
  const { node, parent } = params;

  const docBlock = BLOCKS[parent[0].type];

  return Object.entries(docBlock.insert).map(mapReplace(node, parent));
}

const mapReplace = (node: NodeEntry, parent: NodeEntry) => ([key, value]: [
  string,
  Action
]) => {
  const blockToInsert = BLOCKS[key];

  return {
    node,
    parent,
    title: blockToInsert.title,
    icon: blockToInsert.icon,
    type: key,
    trigger: value
  };
};
