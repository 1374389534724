import { Box } from '@churni/styleguide';
import * as React from 'react';
import { RenderElementProps } from 'slate-react';

/*
 * Renderer for a list item block.
 */
export function BlockListItem(props: RenderElementProps): React.ReactElement {
  const { children, attributes } = props;

  return (
    <Box {...attributes} as={'li'} sx={styles.blockListItem}>
      {children}
    </Box>
  );
}

const styles = {
  blockListItem: {
    position: 'relative'
  },
  blockListItemCheckbox: {
    position: 'absolute',
    top: 4,
    left: -(16 + 4)
  }
};
