import * as React from 'react';
import { createContainer } from 'unstated-next';

// Workaround to force refresh on editor components (used to recompute page order when inserting a page)
function useRefresh() {
  const [counter, setRefresh] = React.useState(0);

  const refresh = () => {
    setRefresh(counter + 1);
  };

  return {
    counter,
    refresh
  };
}

export const Refresh = createContainer(useRefresh);
