import { Editor, Transforms } from 'slate';
import { PaletteInterface } from '../../../containers';
import { ReactEditor } from 'slate-react';

export function insertPaletteAction(
  editor: Editor,
  paletteContainer: PaletteInterface,
  command: object
) {
  if (paletteContainer.search.length) {
    Transforms.delete(editor, {
      unit: 'character',
      reverse: true,
      distance: paletteContainer.search.length
    });
  }

  command.trigger(editor, {
    node: command.node,
    parent: command.parent
  });
  paletteContainer.hide();
  ReactEditor.focus(editor);
}
