import * as React from 'react';
import { Box, Dropdown, Menu, Icon, Flex, Text } from '@churni/styleguide';
import { LogicJumpContext } from './LogicJumpContext';
import { truncate } from './utils';

export function QuestionText(props: {}): React.ReactElement {
  const { selectedQuestion, condition } = React.useContext(LogicJumpContext);

  if (condition === 'variable') {
    return <Text variant="subtitle4">Variable</Text>;
  }

  if (selectedQuestion) {
    return <Text variant="subtitle4">{truncate(selectedQuestion.title)}</Text>;
  }

  return <Text variant="body2">select a question or variable</Text>;
}

export function QuestionPicker(): React.ReactElement {
  const { questions, onQuestionPick, onConditionPick } = React.useContext(
    LogicJumpContext
  );

  const portalRef = React.useRef();

  return (
    <Dropdown
      ref={portalRef}
      position={'south'}
      toggle={
        <Flex alignItems="center">
          <QuestionText />

          <Box color="primaryLight" ml={'4px'}>
            <Icon.ChevronDown />
          </Box>
        </Flex>
      }
    >
      <Box sx={{ minWidth: 300 }}>
        {Object.values(questions)
          .filter(q => q.type === 'multiple-choice')
          .map((question, i) => (
            <Menu.Item
              key={i}
              onClick={() => {
                portalRef.current.close();
                onQuestionPick(question.id);
              }}
              sx={{
                position: 'relative'
              }}
            >
              {truncate(question.title)}
            </Menu.Item>
          ))}

        <Menu.Item
          sx={{ borderTop: 1, borderColor: 'border' }}
          onClick={() => {
            portalRef.current.close();
            onConditionPick('variable');
          }}
        >
          Variable
        </Menu.Item>
      </Box>
    </Dropdown>
  );
}
