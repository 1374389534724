import * as React from 'react';
import { Box, Button, Icon, Text, Tooltip } from '@churni/styleguide';
import { Element } from 'slate';

export function InsertBlock(props: {
  element: Element;
  className: string;
}): React.ReactElement {
  const { className } = props;

  return (
    <Tooltip
      content={
        <Box>
          <Text variant={'body2'}>
            <b>Click</b> to add a block below
          </Text>
        </Box>
      }
    >
      <Button
        variant={'text'}
        icon={<Icon.Plus size={'medium'} />}
        sx={{
          borderRadius: 'circle',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        className={`editor-insert-block ${className}`}
      />
    </Tooltip>
  );
}
