/** @jsx h */
import { Element } from 'slate';
import h from '../../hyperscript';

export function create(text = ''): Element {
  return (
    <jump condition={'always'}>
      <text />
    </jump>
  );
}
