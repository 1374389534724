import { Text } from '@churni/styleguide';
import * as React from 'react';
import { RenderLeafProps } from 'slate-react';

export function MarkItalic(props: RenderLeafProps): React.ReactElement {
  const { children, attributes } = props;

  return (
    <Text {...attributes} sx={{ fontStyle: 'italic' }}>
      {children}
    </Text>
  );
}
