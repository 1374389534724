import { BLOCKS, HEADER_BLOCKS } from '../../../types';
import { Editor, Transforms, Node } from 'slate';

import { Header, Paragraph, Page } from '../../../helpers/elements';

export const withDocumentSchema = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    // Normalize root document

    if (path.length === 0) {
      // If document is empty, add a header
      if (node.children.length < 1) {
        Transforms.insertNodes(editor, Header.create(), {
          at: path.concat(0)
        });
      }

      for (const [child, childPath] of Node.children(editor, path)) {
        // Ensure first block of the document is a header
        if (childPath[0] === 0 && child.type !== BLOCKS.BLOCK_HEADER) {
          Transforms.insertNodes(editor, Header.create(), {
            at: childPath
          });
        }

        // Ensure other blocks are pages, otherwise delete them
        if (
          childPath[0] !== 0 &&
          ![BLOCKS.BLOCK_PAGE, BLOCKS.BLOCK_JUMP].includes(child.type)
        ) {
          if (child.type === BLOCKS.BLOCK_HEADER) {
            Transforms.removeNodes(editor, {
              at: childPath
            });
          } else {
            Transforms.wrapNodes(editor, Page.create(), {
              at: childPath
            });
          }
        }
      }
    }

    // Normalize header block
    if (path.length === 1 && node.type === BLOCKS.BLOCK_HEADER) {
      // Ensure header has at least one children and isn't a single text leaf
      if (node.children.length < 1 || !node.children[0].type) {
        Transforms.insertNodes(editor, Paragraph.create(), {
          at: path.concat(0)
        });
      }

      // Ensure header's children are allowed blocks, otherwise delete them
      const HeaderBlocks = Object.values(HEADER_BLOCKS);

      for (const [child, childPath] of Node.children(editor, path)) {
        if (!HeaderBlocks.includes(child.type)) {
          Transforms.removeNodes(editor, {
            at: childPath
          });
        }
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};
