import { KeyDownProps } from '../types';
import { Editor, Transforms, Text, Path } from 'slate';

import { Paragraph } from '../../../helpers';
import { BLOCK_QUESTION_TITLE } from '../../../types';

export const questionTitle = (props: KeyDownProps, next: () => void) => {
  const { editor, event } = props;

  if (event.key !== 'Enter') {
    return next();
  }

  const [startBlock, path] = Editor.parent(editor, editor.selection.focus.path);

  const handledBlocks = [BLOCK_QUESTION_TITLE];

  if (!handledBlocks.includes(startBlock.type)) {
    return next();
  }

  const [question, questionPath] = Editor.parent(editor, path);

  if (question.questionType === 'text') {
    event.preventDefault();
    const nextPath = Path.next(questionPath);
    const nextTextPath = nextPath.concat(0);
    Transforms.insertNodes(editor, Paragraph.create(), {
      at: nextPath
    });
    Transforms.select(editor, {
      anchor: {
        path: nextTextPath,
        offset: 0
      },
      focus: { path: nextTextPath, offset: 0 }
    });

    return;
  }

  event.preventDefault();
  const [, nextTextPath] = Editor.next(editor, {
    at: path,
    match: n => Text.isText(n)
  });
  Transforms.select(editor, {
    anchor: {
      path: nextTextPath,
      offset: 0
    },
    focus: { path: nextTextPath, offset: 0 }
  });
};
