import {
  getInsertActionsForSelection,
  getWordAtSelection,
  fuzzySearch
} from '../../../helpers';
import { KeyDownProps } from '../types';
import { insertPaletteAction } from '../../../helpers/actions/palette';

export const palette = (props: KeyDownProps, next: () => void) => {
  const { editor, paletteContainer, event } = props;

  const ignoredEvents = [
    'ArrowLeft',
    'ArrowRight',
    'Control',
    'Shift',
    'Alt',
    'Pause',
    'Tab',
    'CapsLock',
    'Meta',
    'Home',
    'End'
  ];

  if (ignoredEvents.includes(event.key)) {
    return next();
  }

  if (paletteContainer.visible) {
    switch (event.key) {
      case 'ArrowUp': {
        event.preventDefault();
        return paletteContainer.setCursor(
          Math.max(0, paletteContainer.cursor - 1)
        );
      }

      case 'ArrowDown': {
        event.preventDefault();
        return paletteContainer.setCursor(
          Math.max(0, paletteContainer.cursor + 1)
        );
      }

      case 'Enter': {
        const command = paletteContainer.results[paletteContainer.cursor];
        if (command) {
          event.preventDefault();
          insertPaletteAction(editor, paletteContainer, command);
        }
        return;
      }
      case ' ':
      case 'Escape': {
        return paletteContainer.hide();
      }
      default: {
        const { word: previousWord } = getWordAtSelection(editor);
        const word =
          event.key === 'Backspace'
            ? previousWord.slice(0, previousWord.length - 1).trim()
            : `${previousWord}${event.key}`.trim();
        const actions = getInsertActionsForSelection(editor);
        const search = word.charAt(0) === '/' ? word.slice(1) : word;

        const filteredResults = fuzzySearch(actions, search, {
          key: 'title'
        });

        paletteContainer.display(word, filteredResults);
      }
    }
  }

  switch (event.key) {
    case '/': {
      const actions = getInsertActionsForSelection(editor);

      paletteContainer.display('/', actions);
      break;
    }
    default:
  }

  return next();
};
