import Fuse, { FuseOptions, FuseResult } from 'fuse.js';

export function fuzzySearch<T>(
  items: T[],
  query: string,
  options: FuseOptions<T>
): Array<FuseResult<T>> {
  const defaultOptions = {
    defaultAllItems: true,
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['title']
  };

  const config = {
    ...defaultOptions,
    ...options
  };

  const fuseInstance = new Fuse(items, config);

  let filteredItems;
  if (query.trim() === '') {
    filteredItems = config.defaultAllItems ? items : [];
  } else {
    filteredItems = fuseInstance.search(query.trim());
  }

  return filteredItems;
}
