import {
  Link,
  Box,
  Icon,
  Button,
  Flex,
  PortalAbsolute
} from '@churni/styleguide';
import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { useIsAnswerMode } from '../../containers';
import { Path, Range } from 'slate';
import { ReactEditor, useEditor, useSlate } from 'slate-react';
import { unwrapLink } from '../../helpers/actions/links';

/*
 * Renderer for an heading block.
 */
export function InlineLink(props: RenderElementProps): React.ReactElement {
  const { element, children, attributes } = props;

  const editor = useEditor();
  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return (
      <Link
        {...attributes}
        variant="primary"
        href={element.url}
        as={'a'}
        target={'_blank'}
      >
        {children}
      </Link>
    );
  }

  const path = ReactEditor.findPath(editor, element);
  const focus = editor.selection && editor.selection.focus.path;

  const isFocusingLink = editor.selection
    ? Path.isAncestor(path, focus)
    : false;

  return (
    <Link {...attributes} variant="primary" href={element.url} as={'a'}>
      {isFocusingLink && <LinkEdit element={element} />}
      {children}
    </Link>
  );
}

export function LinkEdit(props: { element: Element }): React.ReactElement {
  const { element } = props;
  const editor = useSlate();
  const { selection } = editor;
  useSlate();

  const viewRef = React.useRef();
  const [position, setPosition] = React.useState<{
    top: number;
    left: number;
  } | null>(null);

  React.useEffect(() => {
    if (!selection || Range.isExpanded(selection)) {
      return;
    }

    const nativeSelection = window.getSelection();
    if (nativeSelection.rangeCount < 1) {
      return;
    }
    const range = nativeSelection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    setPosition({
      top: rect.top + window.pageYOffset - 8,
      left: rect.left + window.pageXOffset + rect.width / 2
    });
  }, [selection]);

  if (!selection || Range.isExpanded(selection)) {
    return null;
  }

  return (
    <PortalAbsolute>
      <Box
        sx={{
          position: 'absolute',
          borderRadius: 'rounded',
          transform: 'translate(-50%, -100%)',
          ...position,
          bg: 'background'
        }}
      >
        <Flex ref={viewRef} elevation={3} p={1}>
          <Box>{element.url}</Box>
          <Box pl={1}>
            <Button
              className={'editor-remove-link'}
              icon={<Icon.X />}
              variant={'text'}
              onClick={() => {
                unwrapLink(editor);
              }}
            />
          </Box>
        </Flex>
      </Box>
    </PortalAbsolute>
  );
}
