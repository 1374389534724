import * as React from 'react';
import { RenderElementProps } from 'slate-react';

import { BlockWrapper } from '../common';
import { Box } from '@churni/styleguide';
import { useIsAnswerMode } from '../../containers';

/*
 * Renderer for a section body block.
 */
export function BlockPageBody(props: RenderElementProps): React.ReactElement {
  const { children, element, attributes } = props;

  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return <Box {...attributes}>{children}</Box>;
  }

  return (
    <Box {...attributes}>
      <BlockWrapper element={element} controls={false} contentEditable>
        {children}
      </BlockWrapper>
    </Box>
  );
}
