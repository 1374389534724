import { RenderLeafProps } from 'slate-react';
import * as React from 'react';

import { MarkBold, MarkItalic } from './components';

export function renderLeaf(props: RenderLeafProps): React.ReactElement {
  const { leaf, attributes, children } = props;

  if (leaf.bold) {
    return <MarkBold {...props} />;
  }

  if (leaf.italic) {
    return <MarkItalic {...props} />;
  }

  return <span {...attributes}>{children}</span>;
}
