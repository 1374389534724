import * as React from 'react';
import { RenderElementProps, useEditor, ReactEditor } from 'slate-react';

import { Box, Text, Tooltip } from '@churni/styleguide';
import { useIsAnswerMode } from '../../containers';
import { Placeholder } from '../common';
import { Editor } from 'slate';

/*
 * Renderer for a text question answer block.
 */
export function BlockQuestionTextAnswer(
  props: RenderElementProps
): React.ReactElement {
  const { children, element, attributes } = props;

  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return (
      <BlockQuestionTextAnswerAnswer element={element} attributes={attributes}>
        {children}
      </BlockQuestionTextAnswerAnswer>
    );
  }

  return (
    <BlockQuestionTextAnswerEdit element={element} attributes={attributes}>
      {children}
    </BlockQuestionTextAnswerEdit>
  );
}

export function BlockQuestionTextAnswerAnswer(
  props: RenderElementProps
): React.ReactElement {
  const { children, element, attributes } = props;

  const editor = useEditor();
  const path = ReactEditor.findPath(editor, element);
  const isEmpty = !Editor.string(editor, path);

  return (
    <Box
      {...attributes}
      sx={{
        border: 1,
        borderColor: 'border',
        borderRadius: 'rounded',
        p: 3,
        bg: 'background',
        userSelect: 'auto'
      }}
    >
      {isEmpty && (
        <Placeholder>
          <Text color={'secondaryText'}>{`Type your answer here...`}</Text>
        </Placeholder>
      )}
      <Text>{children}</Text>
    </Box>
  );
}

export function BlockQuestionTextAnswerEdit(
  props: RenderElementProps
): React.ReactElement {
  const { attributes } = props;

  return (
    <Box {...attributes} contentEditable={false} sx={{ userSelect: 'none' }}>
      <Tooltip content={'You cannot answer this question in edition mode'}>
        <Box
          sx={{
            cursor: 'disabled',
            border: 1,
            borderColor: 'border',
            borderRadius: 'rounded',
            p: 3,
            bg: 'background'
          }}
        >
          <Text color={'secondaryText'}>Type your answer here...</Text>
        </Box>
      </Tooltip>
    </Box>
  );
}
