/** @jsx h */
import { Element } from 'slate';
import h from '../../hyperscript';

export function create(text = ''): Element {
  return (
    <paragraph>
      <text>{text}</text>
    </paragraph>
  );
}
