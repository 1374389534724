import * as React from 'react';
import { Transforms, Editor } from 'slate';
import { useEditor, ReactEditor } from 'slate-react';

import {
  Grid,
  Box,
  Text,
  Flex,
  Icon,
  useHover,
  Button
} from '@churni/styleguide';
import { collect, retain } from '../../templates';
import {
  isEmptyDocument,
  getNodesForPath,
  getInsertActions
} from '../../helpers';
import { Palette, CompanyContext } from '../../containers';

/*
 * Template picker when document is empty
 */
export function TemplateBlankslate(): React.ReactElement {
  const editor = useEditor();

  const { display } = Palette.useContainer();
  const { name } = React.useContext(CompanyContext);
  const templates = [collect, retain];

  const empty = editor.children && isEmptyDocument(editor);
  const [ref, isHover] = useHover();

  const lastNode = [
    editor.children[editor.children.length - 1],
    [editor.children.length - 1]
  ];

  if (!empty) {
    return (
      <Flex
        contentEditable={false}
        ref={ref}
        onClick={async () => {
          ReactEditor.focus(editor);
          const pagePath = editor.insertPage({
            node: lastNode,
            parent: null
          });

          const nodes = getNodesForPath(editor, pagePath.concat(0).concat(0));

          display('', getInsertActions(editor, nodes));
        }}
        alignItems={'center'}
        justifyContent="center"
        elevation={isHover ? 3 : 2}
        sx={{
          userSelect: 'none',
          cursor: 'pointer'
        }}
        mt={4}
        py={3}
      >
        <Text variant="subtitle3" icon={<Icon.Plus />}>
          Add a new page
        </Text>
      </Flex>
    );
  }

  const onClick = (template: any) => {
    const blocks = template.create(name).children;

    Editor.withoutNormalizing(editor, () => {
      blocks.forEach((block: Element, index: number) => {
        Transforms.insertNodes(editor, block, { at: [index] });
      });
    });
  };

  return (
    <Box mt={4}>
      <Text variant={'overline'}>Get started with a template</Text>
      <Grid columns={2} rowGap={2} columnGap={3} mt={2}>
        {templates.map(template => (
          <TemplateCard
            className={`editor-insert-template-${template.name}`}
            key={template.name}
            onClick={onClick}
            template={template}
          />
        ))}
      </Grid>
    </Box>
  );
}

function TemplateCard(props: {
  template: any;
  onClick: (template: any) => void;
}): React.ReactElement {
  const { template, onClick, ...rest } = props;

  const [ref, isHover] = useHover();

  return (
    <Button
      ref={ref}
      variant="primaryOutline"
      onClick={() => onClick(template)}
      sx={{ whiteSpace: 'wrap', borderRadius: 'rounded' }}
      px={3}
      py={2}
      {...rest}
    >
      <Box>
        <Text variant="subtitle3">{template.name}</Text>
      </Box>
      <Box mt={1}>
        <Text variant="body2" color={isHover ? 'textOnPrimary' : 'text'}>
          {template.description}
        </Text>
      </Box>
    </Button>
  );
}
