import * as React from 'react';
import { RenderElementProps } from 'slate-react';

import { Box } from '@churni/styleguide';
import { useIsAnswerMode } from '../../containers';

/*
 * Renderer for a header block.
 */
export function BlockHeader(props: RenderElementProps): React.ReactElement {
  const { children, ...attributes } = props;

  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return (
      <Box mx={3} {...attributes}>
        {children}
      </Box>
    );
  }

  return (
    <Box {...attributes} mx={3}>
      {children}
    </Box>
  );
}
