/** @jsx h */
import { Element } from 'slate';
import h from '../../hyperscript';
/*
 * Create an empty item
 */
export function createListItem(): Element {
  return (
    <list-item>
      <text />
    </list-item>
  );
}

/*
 * Create an empty list
 */
export function createList(
  args: {
    ordered?: boolean;
  } = {
    ordered: false
  }
): Element {
  if (args.ordered) {
    return <list-ordered>{createListItem()}</list-ordered>;
  }

  return <list-unordered>{createListItem()}</list-unordered>;
}
