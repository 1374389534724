import * as React from 'react';
import { RenderElementProps, ReactEditor, useEditor } from 'slate-react';

import { Box, Flex, Button, Icon, Text } from '@churni/styleguide';
import { useIsAnswerMode, usePage, PagesContext } from '../../containers';
import { Transforms } from 'slate';
import { Page } from '../../helpers';

/*
 * Renderer for a page actions block.
 */
export function BlockPageActions(
  props: RenderElementProps
): React.ReactElement {
  const { children, attributes, element } = props;
  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return (
      <BlockPageActionsAnswer element={element} attributes={attributes}>
        {children}
      </BlockPageActionsAnswer>
    );
  }

  return (
    <BlockPageActionsEdit element={element} attributes={attributes}>
      {children}
    </BlockPageActionsEdit>
  );
}

export function BlockPageActionsAnswer(
  props: RenderElementProps
): React.ReactElement {
  const { children, attributes, element } = props;

  const { previous } = usePage();
  const hasPreviousButton = useHasPreviousButton(element);

  return (
    <Box
      {...attributes}
      mt={2}
      sx={{ userSelect: 'none', borderTop: 1, borderColor: 'border', pt: 3 }}
      contentEditable={false}
    >
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ position: 'relative' }}
      >
        {hasPreviousButton ? (
          <Box>
            <Button
              className="form-previous-button"
              variant={'text'}
              onClick={previous}
              sx={{
                px: 0,
                ml: [-6, -1],
                position: ['absolute', 'relative'],
                top: '50%',
                transform: ['translateY(-50%)', 'none']
              }}
              icon={<Icon.ChevronLeft />}
            >
              <Text sx={{ display: ['none', 'block'] }}>Previous</Text>
            </Button>
          </Box>
        ) : (
          <Box />
        )}
        <Flex
          alignItems={'center'}
          justifyContent={['center', 'left']}
          flexWrap={['wrap', 'nowrap']}
        >
          {children}
        </Flex>
      </Flex>
    </Box>
  );
}

export function BlockPageActionsEdit(
  props: RenderElementProps
): React.ReactElement {
  const { children, attributes, element } = props;

  const editor = useEditor();
  const hasPreviousButton = useHasPreviousButton(element);

  const onInsertSecondaryCTA = () => {
    const path = ReactEditor.findPath(editor, element);

    Transforms.insertNodes(editor, Page.createPageActionSecondary(), {
      at: path.concat(0)
    });
  };

  return (
    <Box
      {...attributes}
      mt={2}
      sx={{ borderTop: 1, borderColor: 'border', pt: 3, userSelect: 'none' }}
      contentEditable={false}
    >
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ position: 'relative' }}
      >
        {hasPreviousButton ? (
          <Box>
            <Button
              className="form-previous-button"
              variant={'text'}
              sx={{
                px: 0,
                ml: [-6, -1],
                position: ['absolute', 'relative'],
                top: '50%',
                transform: ['translateY(-50%)', 'none']
              }}
              icon={<Icon.ChevronLeft />}
            >
              <Text sx={{ display: ['none', 'block'] }}>Previous</Text>
            </Button>
          </Box>
        ) : (
          <Box />
        )}
        <Flex
          alignItems={'center'}
          justifyContent={['center', 'left']}
          flexWrap={['wrap', 'nowrap']}
        >
          {element.children.length === 1 && (
            <Button
              variant="text"
              icon={<Icon.Plus />}
              onClick={onInsertSecondaryCTA}
            >
              Add another button
            </Button>
          )}
          {children}
        </Flex>
      </Flex>
    </Box>
  );
}

function useHasPreviousButton(element: Element) {
  const editor = useEditor();

  const path = ReactEditor.findPath(editor, element);
  const pages = React.useContext(PagesContext);

  const index = pages.findIndex(page => {
    return page[1][0] === path[0];
  });

  return index > 0;
}
