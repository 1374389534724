import * as React from 'react';
import { Box, Flex, Icon, Text } from '@churni/styleguide';
import { Transforms } from 'slate';
import { useEditor, ReactEditor } from 'slate-react';
import * as slugify from 'slugify';
import { QuestionNodesContext } from '../../containers';
import { LogicJumpContext } from './LogicJumpContext';
import { PagePicker } from './PagePicker';
import { ConditionPicker } from './ConditionPicker';
import { getQuestions } from '../../helpers';
import { QuestionPicker } from './QuestionPicker';
import { AnswerPicker } from './AnswerPicker';
import { VariablePicker } from './VariablePicker';

export function LogicJumpEdit(props: { element: any }): React.ReactElement {
  const { element } = props;

  const editor = useEditor();
  const jumpPath = ReactEditor.findPath(editor, element);
  const questionNodesContext = React.useContext(QuestionNodesContext);

  const questions = getQuestions(
    editor,
    questionNodesContext.filter(q => q[1][0] < jumpPath[0])
  );

  const pageID = element.pageID || null;
  const condition = element.condition || null;
  const questionID = element.questionID || null;
  const answerID = element.answerID || null;
  const variableSlug = element.variableSlug || null;
  const variableValue = element.variableValue || '';

  const onPagePick = (value: string) => {
    Transforms.setNodes(editor, { pageID: value }, { at: jumpPath });
  };

  const onConditionPick = (condition: string) => {
    Transforms.setNodes(editor, { condition }, { at: jumpPath });
  };

  const onQuestionPick = (questionID: string) => {
    Transforms.setNodes(
      editor,
      { questionID, condition: 'question' },
      { at: jumpPath }
    );
  };

  const onAnswerPick = (questionID: string, answerID: string) => {
    Transforms.setNodes(editor, { questionID }, { at: jumpPath });
    Transforms.setNodes(editor, { answerID }, { at: jumpPath });
  };

  const onVariableValueChange = (newVariableValue: string) => {
    Transforms.setNodes(
      editor,
      { variableValue: newVariableValue },
      { at: jumpPath }
    );
  };

  const onVariableSlugChange = (newVariableSlug: string) => {
    Transforms.setNodes(
      editor,
      { variableSlug: slugify(newVariableSlug) },
      { at: jumpPath }
    );
  };

  const selectedQuestion =
    questions && questionID ? questions[questionID] : null;
  const answers = selectedQuestion ? selectedQuestion.answers : [];
  const selectedAnswer = answers && answerID ? answers[answerID] : null;

  return (
    <LogicJumpContext.Provider
      value={{
        selectedQuestion,
        selectedAnswer,
        variableValue,
        variableSlug,
        condition,
        onConditionPick,
        onAnswerPick,
        onVariableSlugChange,
        onQuestionPick,
        onPagePick,
        pageID,
        jumpPath,
        onVariableValueChange,
        questions
      }}
    >
      <Flex
        flexDirection={['column', 'row']}
        alignItems={'center'}
        sx={{ fontSize: 1 }}
      >
        <Flex alignItems="center" color="primaryLight">
          <Icon.CornerDownRight />
          <Box ml={1} mr={2}>
            <Text variant={'overline'}>Jump to</Text>
          </Box>
        </Flex>
        <Box mr={1}>
          <PagePicker />
        </Box>
        <Box mr={1}>
          <ConditionPicker />
        </Box>
        {condition !== 'always' && (
          <Box mr={1}>
            <QuestionPicker />
          </Box>
        )}

        {condition === 'variable' && <VariablePicker />}

        {condition === 'question' && selectedQuestion && (
          <>
            <Box mr={1}>
              <Text variant="overline" color="primaryLight">
                is
              </Text>
            </Box>
            <Box>
              <AnswerPicker />
            </Box>
          </>
        )}
      </Flex>
    </LogicJumpContext.Provider>
  );
}
