import { Editor } from 'slate';
import { BLOCKS } from '../../types';

export const truncate = (text: string, length = 25) => {
  if (!text || text.length < length) {
    return text;
  } else {
    return `${text.slice(0, length)}...`;
  }
};

export const getPageIndex = (editor: Editor, path: array) => {
  const pages = Array.from(
    Editor.nodes(editor, {
      match: n => n.type === BLOCKS.BLOCK_PAGE,
      at: []
    })
  );

  const index = pages.findIndex(page => {
    return page[1].join(',') === path.join(',');
  });
  return index + 1;
};

export const getPagesAfter = (editor: Editor, jumpPath: array) => {
  return Array.from(
    Editor.nodes(editor, { at: [], match: n => n.type === BLOCKS.BLOCK_PAGE })
  ).filter(p => p[1][0] > jumpPath[0]);
};
