import * as types from './types';
import { Icon } from '@churni/styleguide';
import { Element, Editor } from 'slate';
import { Question } from './helpers';

export type Action = (
  editor: Editor,
  context: { block: Element; parent: Element }
) => void;

export interface Actions {
  [key: string]: Action;
}

export interface Block {
  type: string;
  title: string;
  icon: string;
  insert: {
    [key: string]: (
      editor: Editor,
      context: { block: Element; parent: Element }
    ) => void;
  };
  replace: {
    [key: string]: (
      editor: Editor,
      context: { block: Element; parent: Element }
    ) => void;
  };
}

export type Blocks = { [key: string]: Block };

const BASIC_TEXT_REPLACE_ACTIONS: Actions = {
  [types.BLOCK_PARAGRAPH]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.replaceParagraph(context),
  [types.BLOCK_HEADING_1]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.replaceH1(context),
  [types.BLOCK_HEADING_2]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.replaceH2(context),
  [types.BLOCK_HEADING_3]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.replaceH3(context)
};

const BASIC_TEXT_INSERT_ACTIONS: Actions = {
  [types.BLOCK_PARAGRAPH]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.insertParagraph(context),
  [types.BLOCK_HEADING_1]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.insertH1(context),
  [types.BLOCK_HEADING_2]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.insertH2(context),
  [types.BLOCK_HEADING_3]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.insertH3(context)
};

const BASIC_INSERT_ACTIONS: Actions = {
  ...BASIC_TEXT_INSERT_ACTIONS,
  [types.BLOCK_PAGE]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.insertPage(context),
  [types.BLOCK_LIST_OL]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.insertListOrdered(context),
  [types.BLOCK_LIST_UL]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.insertListUnordered(context),
  [types.BLOCK_QUESTION_BODY]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) =>
    editor.insertPage(context, Question.createMultipleChoiceQuestionWithPage()),

  [types.BLOCK_QUESTION_TEXT_ANSWER]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.insertPage(context, Question.createTextQuestionWithPage())
};

const PAGE_BODY_ACTIONS: Actions = {
  ...BASIC_INSERT_ACTIONS,
  [types.BLOCK_QUESTION_BODY]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.insertMultipleChoiceQuestion(context),
  [types.BLOCK_QUESTION_TEXT_ANSWER]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.insertTextQuestion(context),
  [types.BLOCK_CALENDLY]: (
    editor: Editor,
    context: { block: Element; parent: Element }
  ) => editor.insertCalendly(context)
};

const QUESTION_REPLACE_ACTIONS: Actions = {
  // [types.BLOCK_QUESTION_MULTIPLE_CHOICE]: (editor: Editor, block: Element) =>
  //   editor.replaceByMultipleChoiceAnswer(block),
  // [types.BLOCK_QUESTION_TEXT_ANSWER]: (editor: Editor, block: Element) =>
  //   editor.replaceByTextAnswer(block)
};

export const DOCUMENT_BLOCKS: Blocks = {
  [types.BLOCK_HEADER]: {
    type: types.BLOCK_HEADER,
    title: 'Header',
    icon: Icon.Paragraph,
    insert: BASIC_INSERT_ACTIONS,
    replace: {}
  },
  [types.BLOCK_PAGE]: {
    type: types.BLOCK_PAGE,
    title: 'Page',
    icon: Icon.Layers,
    insert: BASIC_INSERT_ACTIONS,
    replace: {}
  },
  [types.BLOCK_JUMP]: {
    type: types.BLOCK_JUMP,
    title: 'JUMP',
    icon: Icon.CornerDownRight,
    insert: BASIC_INSERT_ACTIONS,
    replace: {}
  }
};

export const PAGE_BLOCKS: Blocks = {
  [types.BLOCK_PAGE_HEADER]: {
    type: types.BLOCK_PAGE_HEADER,
    title: 'Page Header',
    icon: null,
    insert: BASIC_INSERT_ACTIONS,
    replace: {}
  },
  [types.BLOCK_PAGE_BODY]: {
    type: types.BLOCK_PAGE_BODY,
    title: 'Page Body',
    icon: null,
    insert: PAGE_BODY_ACTIONS,
    replace: {}
  },
  [types.BLOCK_PAGE_FOOTER]: {
    type: types.BLOCK_PAGE_FOOTER,
    title: 'Page Footer',
    icon: null,
    insert: BASIC_INSERT_ACTIONS,
    replace: {}
  }
};

export const PAGE_BODY_BLOCKS: Blocks = {
  [types.BLOCK_HEADING_1]: {
    type: types.BLOCK_HEADING_1,
    title: 'Heading 1',
    icon: Icon.Heading1,
    insert: {},
    replace: BASIC_TEXT_REPLACE_ACTIONS
  },
  [types.BLOCK_HEADING_2]: {
    type: types.BLOCK_HEADING_2,
    title: 'Heading 2',
    icon: Icon.Heading2,
    insert: {},
    replace: BASIC_TEXT_REPLACE_ACTIONS
  },
  [types.BLOCK_HEADING_3]: {
    type: types.BLOCK_HEADING_3,
    title: 'Heading 3',
    icon: Icon.Heading3,
    insert: {},
    replace: BASIC_TEXT_REPLACE_ACTIONS
  },
  [types.BLOCK_PARAGRAPH]: {
    type: types.BLOCK_PARAGRAPH,
    title: 'Paragraph',
    icon: Icon.Paragraph,
    insert: {},
    replace: BASIC_TEXT_REPLACE_ACTIONS
  },
  [types.BLOCK_CALENDLY]: {
    type: types.BLOCK_CALENDLY,
    title: 'Calendly',
    icon: Icon.Calendar,
    insert: {},
    replace: {}
  },

  [types.BLOCK_LIST_OL]: {
    type: types.BLOCK_LIST_OL,
    title: 'List Ordered',
    icon: Icon.ListOrdered,
    insert: {},
    replace: {}
  },
  [types.BLOCK_LIST_UL]: {
    type: types.BLOCK_LIST_UL,
    title: 'List Unordered',
    icon: Icon.ListUnordered,
    insert: {},
    replace: {}
  },
  [types.BLOCK_QUESTION]: {
    type: types.BLOCK_QUESTION,
    title: 'Question',
    icon: Icon.CheckSquare,
    insert: {},
    replace: QUESTION_REPLACE_ACTIONS
  }
};

export const NESTED_BLOCKS: Blocks = {
  [types.BLOCK_PARAGRAPH]: {
    type: types.BLOCK_PARAGRAPH,
    title: 'Paragraph',
    icon: Icon.Paragraph,
    insert: {},
    replace: {}
  },
  [types.BLOCK_LIST_ITEM]: {
    type: types.BLOCK_LIST_ITEM,
    title: 'List Item',
    icon: Icon.ListUnordered,
    insert: {},
    replace: {}
  },
  [types.BLOCK_QUESTION_BODY]: {
    type: types.BLOCK_QUESTION_BODY,
    title: 'Multiple Choice Question',
    icon: Icon.CheckSquare,
    insert: {},
    replace: {}
  },
  [types.BLOCK_QUESTION_TEXT_ANSWER]: {
    type: types.BLOCK_QUESTION_TEXT_ANSWER,
    title: 'Text Question',
    icon: Icon.Type,
    insert: {},
    replace: {}
  },
  [types.BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM]: {
    type: types.BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM,
    title: 'Multiple Choice Item',
    icon: Icon.Paragraph,
    insert: {},
    replace: {}
  }
};

export const BLOCKS = {
  ...NESTED_BLOCKS,
  ...PAGE_BLOCKS,
  ...PAGE_BODY_BLOCKS,
  ...DOCUMENT_BLOCKS
};
