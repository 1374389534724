import * as React from 'react';
import { RenderElementProps, ReactEditor, useEditor } from 'slate-react';

import { Box } from '@churni/styleguide';
import { BlockWrapper } from '../common';
import { usePage, useIsAnswerMode } from '../../containers';
import { Transforms } from 'slate';
import { QuestionContext } from './QuestionContext';

/*
 * Renderer for a question block.
 */
export function BlockQuestion(props: RenderElementProps): React.ReactElement {
  const { element, children, attributes } = props;

  const isAnswerMode = useIsAnswerMode();
  const editor = useEditor();
  const path = ReactEditor.findPath(editor, element);
  const required = element.required;
  const multipleAnswers = element.multipleAnswers;
  const questionType = element.questionType;

  const onRequiredChange = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    Transforms.setNodes(editor, { required: !required }, { at: path });
  };

  const onMultipleAnswersChange = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    Transforms.setNodes(
      editor,
      { multipleAnswers: !multipleAnswers },
      { at: path }
    );
  };

  return (
    <QuestionContext.Provider
      value={{
        required,
        multipleAnswers,
        questionType,
        onRequiredChange,
        onMultipleAnswersChange
      }}
    >
      {isAnswerMode ? (
        <BlockQuestionAnswer element={element} attributes={attributes}>
          {children}
        </BlockQuestionAnswer>
      ) : (
        <BlockQuestionEdit element={element} attributes={attributes}>
          {children}
        </BlockQuestionEdit>
      )}
    </QuestionContext.Provider>
  );
}

export function BlockQuestionEdit(
  props: RenderElementProps
): React.ReactElement {
  const { element, children, attributes } = props;

  return (
    <Box {...attributes}>
      <BlockWrapper element={element} contentEditable>
        {children}
      </BlockWrapper>
    </Box>
  );
}

export function BlockQuestionAnswer(
  props: RenderElementProps
): React.ReactElement {
  const { children, element, attributes } = props;

  const { errors } = usePage();
  const isTextAnswer = element.questionType === 'text';

  const questionParams = isTextAnswer
    ? {}
    : { contentEditable: false, sx: { userSelect: 'none' } };

  return (
    <Box {...attributes}>
      {errors[element.id] && (
        <Box color={'red'} contentEditable={false} sx={{ userSelect: 'none' }}>
          {errors[element.id].error}
        </Box>
      )}
      <Box {...questionParams}>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
}
