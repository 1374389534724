import { Editor } from 'slate';
import { withInsertBlockCommands } from './insert';
import { withReferences } from './references';

import { withReplaceBlockCommands } from './replace';
import { withMarks } from './marks';
import { withLinks } from './links';
import { withHistory } from 'slate-history';
import { withEmbeds } from './embeds';
import { withJumps } from './jumps';
import { withSchema } from './schema';

export function withPlugins(editor: Editor) {
  return withHistory(
    withSchema(
      withEmbeds(
        withJumps(
          withReferences(
            withInsertBlockCommands(
              withMarks(withLinks(withReplaceBlockCommands(editor)))
            )
          )
        )
      )
    )
  );
}
