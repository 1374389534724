import { KeyDownProps } from '../types';
import { Range } from 'slate';
import { ReactEditor } from 'slate-react';
import { MARK_ITALIC, MARK_BOLD } from '../../../types';
import { isLinkActive } from '../../../helpers/queries/links';
import { unwrapLink } from '../../../helpers/actions/links';

export const selectionShortcutsHandler = (
  props: KeyDownProps,
  next: () => void
) => {
  const { editor, event } = props;

  if (!event.ctrlKey || !['b', 'k', 'i'].includes(event.key)) {
    return next();
  }

  const isSelected =
    Range.isExpanded(editor.selection) && ReactEditor.isFocused(editor);

  if (!isSelected) {
    return next();
  }

  switch (event.key) {
    case 'b': {
      editor.toggleMark(MARK_BOLD);
      event.preventDefault();
      return;
    }
    case 'i': {
      editor.toggleMark(MARK_ITALIC);
      event.preventDefault();
      return;
    }
    case 'k': {
      const isActiveLink = isLinkActive(editor);
      event.preventDefault();
      if (isActiveLink) {
        unwrapLink(editor);
      } else {
        const url = window.prompt('Enter the URL of the link:');
        if (!url) return;
        editor.insertLink(url);
      }
      return;
    }
    default:
  }

  return next();
};
