import * as React from 'react';
import { Box, Dropdown, Menu, Text, Flex, Icon } from '@churni/styleguide';

import { LogicJumpContext } from './LogicJumpContext';
import { truncate } from './utils';

export function ConditionText(props: {}): React.ReactElement {
  const {
    selectedQuestion,
    variableSlug,
    selectedAnswer,
    variableValue,
    condition
  } = React.useContext(LogicJumpContext);

  if (condition === 'question') {
    if (selectedAnswer) {
      return `If answer for question ${truncate(
        selectedQuestion.title
      )} is  ${truncate(selectedAnswer.text)}`;
    }

    if (selectedQuestion) {
      return `If answer for question ${truncate(
        selectedQuestion.title
      )} is ...`;
    }

    return `If answer for question ... is ...`;
  }

  if (condition === 'variable') {
    if (variableSlug && variableValue) {
      return `If variable ${truncate(variableSlug)} equals ${truncate(
        variableValue
      )}`;
    }

    if (variableSlug) {
      return `If variable ${truncate(variableSlug)} equals ...`;
    }

    return `If variable ... equals`;
  }

  return `In all cases`;
}

export function ConditionPicker(): React.ReactElement {
  const { condition, onConditionPick } = React.useContext(LogicJumpContext);

  const portalRef = React.useRef();

  return (
    <Flex alignItems="center">
      <Dropdown
        ref={portalRef}
        position={'south'}
        toggle={
          <Flex alignItems="center">
            <Text variant="subtitle4">
              {condition === 'always' ? 'in all cases' : 'if'}
            </Text>

            <Box color="primaryLight" ml={'4px'}>
              <Icon.ChevronDown />
            </Box>
          </Flex>
        }
      >
        <Box sx={{ minWidth: 200, position: 'relative' }}>
          <Menu.Item
            onClick={() => {
              portalRef.current.close();
              onConditionPick('always');
            }}
          >
            in all cases
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              portalRef.current.close();
              onConditionPick('variable');
            }}
          >
            if
          </Menu.Item>
        </Box>
      </Dropdown>
    </Flex>
  );
}
