import { Editor, Transforms } from 'slate';
import * as urlModule from 'url';
import { isLinkActive } from '../../';
import { unwrapLink } from './unwrapLink';

export function wrapLink(editor: Editor, url: string) {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  // Ensure the url is absolute and defaults it to https
  const parsed = urlModule.parse(url);

  // url without protocol are not resolved correctly by url module
  const normalizedUrl = parsed.protocol
    ? urlModule.format(parsed)
    : `https://${url}`;

  const link = { type: 'link', url: normalizedUrl, children: [] };
  Transforms.wrapNodes(editor, link, { split: true });
  Transforms.collapse(editor, { edge: 'end' });
}
