import * as React from 'react';
import { useSlate } from 'slate-react';
import { Box, Icon } from '@churni/styleguide';
import { isMarkActive, isLinkActive, unwrapLink } from '../../helpers';
import { INLINE_LINK, MARK_BOLD, MARK_ITALIC } from '../../types';

export function TextFormatting(): React.ReactElement {
  const editor = useSlate();

  return (
    <>
      <FormatButton
        className={'editor-bold-button'}
        icon={<Icon.Bold />}
        type={MARK_BOLD}
        onClick={() => editor.toggleMark(MARK_BOLD)}
      />
      <FormatButton
        className={'editor-italic-button'}
        icon={<Icon.Italic />}
        type={MARK_ITALIC}
        onClick={() => editor.toggleMark(MARK_ITALIC)}
      />
      <FormatButton
        className={'editor-link-button'}
        icon={<Icon.Link />}
        type={INLINE_LINK}
        onClick={() => {
          const isActiveLink = isLinkActive(editor);

          if (isActiveLink) {
            unwrapLink(editor);
          } else {
            const url = window.prompt('Enter the URL of the link:');
            if (!url) return;
            editor.insertLink(url);
          }
        }}
      />
    </>
  );
}

function FormatButton(props: {
  onClick: () => void;
  type: string;
  icon: React.ReactNode;
}): React.ReactElement {
  const { icon, type, onClick, ...rest } = props;

  const editor = useSlate();
  const onMouseDown = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick();
  };

  const isActive = isMarkActive(editor, type);

  return (
    <Box
      sx={{
        cursor: 'pointer',
        padding: 1,
        color: 'primary'
      }}
      color={isActive ? 'secondary' : 'primary'}
      onMouseDown={onMouseDown}
      {...rest}
    >
      {icon}
    </Box>
  );
}
