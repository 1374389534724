import { Editor, Element } from 'slate';
import { BLOCK_CALENDLY } from '../../types';

export const withEmbeds = (editor: Editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element: Element) => {
    return element.type === BLOCK_CALENDLY ? true : isVoid(element);
  };

  return editor;
};
