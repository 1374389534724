import { getWordAtSelection, insertReference } from '../../../helpers';

import { KeyDownProps } from '../types';

export const reference = (props: KeyDownProps, next: () => void) => {
  const { editor, referenceContainer, event, variablesContainer } = props;

  const variables = variablesContainer;

  const ignoredEvents = [
    'ArrowLeft',
    'ArrowRight',
    'Control',
    'Shift',
    'Alt',
    'Pause',
    'Tab',
    'CapsLock',
    'Meta',
    'Home',
    'End'
  ];

  if (ignoredEvents.includes(event.key)) {
    return next();
  }

  if (referenceContainer.visible) {
    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowRight':
        return next();
      case 'ArrowUp': {
        event.preventDefault();
        return referenceContainer.setCursor(
          Math.max(0, referenceContainer.cursor - 1)
        );
      }

      case 'ArrowDown': {
        event.preventDefault();
        return referenceContainer.setCursor(
          Math.min(
            referenceContainer.results.length - 1,
            referenceContainer.cursor + 1
          )
        );
      }

      case 'Enter': {
        event.preventDefault();
        insertReference(
          editor,
          referenceContainer,
          referenceContainer.results[referenceContainer.cursor]
        );
        return;
      }
      case ' ':
      case 'Escape': {
        return referenceContainer.hide();
      }
      default: {
        const { word: previousWord } = getWordAtSelection(editor);
        const word =
          event.key === 'Backspace'
            ? previousWord.slice(0, previousWord.length - 1).trim()
            : `${previousWord}${event.key}`.trim();

        if (!word && event.key === 'Backspace') {
          referenceContainer.hide();
          return;
        }

        const search = word.charAt(0) === '@' ? word.slice(1) : word;

        // const filteredResults = fuzzySearch(Object.values(variables), search, {
        //   includeScore: true
        // });

        // const exactMatch = filteredResults.find(result => result.score === 0);

        // const items = [
        //   // ...filteredResults.map(result => result.item || result),
        //   ...(search && !exactMatch
        //     ? [{ type: 'new', title: `Create ${search}` }]
        //     : [])
        // ];

        referenceContainer.display(search, [
          { type: 'new', title: `Add reference ${search}` }
        ]);
      }
    }
  }

  switch (event.key) {
    case '@': {
      const { word } = getWordAtSelection(editor);

      if (!word.trim()) {
        referenceContainer.display('@', Object.values(variables));
      }
      break;
    }
    default:
  }

  return next();
};
