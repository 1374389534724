import * as React from 'react';
import { createContainer } from 'unstated-next';
import { InsertAction } from '../helpers/queries/insert';

export interface PaletteInterface {
  visible: boolean;
  search: string;
  results: Array<InsertAction>;
  cursor: number;
  setCursor: (value: number) => void;
  display: (search: string, results: Array<any>) => void;
  hide: () => void;
}

function usePaletteState() {
  const [visible, setVisible] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [results, setResults] = React.useState([]);
  const [cursor, setCursor] = React.useState(0);

  const display = (search: string, results: any = []) => {
    setVisible(true);
    setResults(results);
    setCursor(0);
    setSearch(search);
  };

  const hide = () => {
    setVisible(false);
    setResults([]);
  };

  return {
    visible,
    search,
    results,
    cursor,
    setCursor,
    display,
    hide
  };
}

export const Palette = createContainer(usePaletteState);
