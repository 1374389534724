/** @jsx h */
import { Element } from 'slate';
import h from '../../hyperscript';

export function createH1(): Element {
  return (
    <heading-1>
      <text />
    </heading-1>
  );
}

export function createH2(): Element {
  return (
    <heading-2>
      <text />
    </heading-2>
  );
}

export function createH3(): Element {
  return (
    <heading-3>
      <text />
    </heading-3>
  );
}
