import { Editor, Element } from 'slate';
import { INLINE_LINK } from '../../types';
import { wrapLink } from '../../helpers';

export const withLinks = (editor: Editor) => {
  const { isInline } = editor;

  editor.isInline = (element: Element) => {
    return element.type === INLINE_LINK ? true : isInline(element);
  };

  editor.insertLink = (url: string) => {
    if (editor.selection) {
      wrapLink(editor, url);

      return;
    }
  };

  return editor;
};
