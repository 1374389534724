/** @jsx h */
import h from '../../hyperscript';
import { Element } from 'slate';

/*
 * Create a calendly block.
 */
export function createCalendly(url: string): Element {
  return (
    <calendly url={url}>
      <text />
    </calendly>
  );
}
