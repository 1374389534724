import * as React from 'react';
import { useEditor } from 'slate-react';

import { getAnswers } from '../helpers';
import { PagesContext } from './Pages';
import { JumpsContext } from './Jumps';
import { QuestionNodesContext } from './Questions';
import { VariablesContext } from './Variables';

export interface Page {
  currentPage: number;
  previous: () => void;
  next: () => void;
  onFinish: () => void;
  errors: {
    [questionID: string]: { error: string; type: string };
  };
  setErrors: (errors: {
    [questionID: string]: { error: string; type: string };
  }) => void;
}

export const PageContext = React.createContext<Page | {}>({});

const findPageIndex = (pages: any, jump: any) =>
  pages.findIndex(page => page[0].id === jump[0].pageID);

export const usePage = (): Page => {
  const editor = useEditor();
  const jumps = React.useContext(JumpsContext);
  const pages = React.useContext(PagesContext);
  const questionsContext = React.useContext(QuestionNodesContext);
  const variablesContext = React.useContext(VariablesContext);

  const {
    currentPage,
    previous,
    next,
    onFinish,
    onCancel,
    errors,
    setErrors,
    jumpsState,
    setJumpsState
  } = React.useContext(PageContext);

  const onPrevious = () => {
    if (jumpsState[currentPage] !== undefined) {
      previous(jumpsState[currentPage]);
    } else {
      previous();
    }
  };

  const onNext = () => {
    const currentPath = pages[currentPage][1];
    const nextPath = pages[currentPage + 1][1];

    // Filter jumps between the pages
    const filteredJumps = jumps.filter(jump => {
      return jump[1][0] > currentPath[0] && jump[1][0] < nextPath[0];
    });

    // Loop through the different jumps by priority
    const jumpIndexes = filteredJumps
      .map(jump => {
        if (jump[0].condition === 'always') {
          return findPageIndex(pages, jump);
        }

        if (jump[0].condition === 'question') {
          const answers = getAnswers(editor, questionsContext);

          const jumpQuestion = answers[jump[0].questionID];
          const jumpAnswer = jumpQuestion
            ? jumpQuestion.answers.find(
                answer => answer.id === jump[0].answerID
              )
            : null;

          if (jumpAnswer) {
            return findPageIndex(pages, jump);
          }
        }

        if (jump[0].condition === 'variable') {
          const variable = variablesContext[jump[0].variableID];

          if (variable === jump[0].variableValue) {
            return findPageIndex(pages, jump);
          }
        }

        return null;
      })
      .filter(Boolean);

    const nextPage = jumpIndexes[0] || currentPage + 1;

    setJumpsState({
      ...jumpsState,
      [nextPage]: currentPage
    });

    next(nextPage);
  };

  return {
    currentPage,
    previous: onPrevious,
    next: onNext,
    onFinish,
    onCancel,
    errors,
    setErrors
  };
};
