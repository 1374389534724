import { Editor } from 'slate';
import { isMarkActive } from '../../helpers';

export const withMarks = (editor: Editor) => {
  editor.toggleMark = (type: string) => {
    const isActive = isMarkActive(editor, type);

    if (isActive) {
      Editor.removeMark(editor, type);
      return;
    }

    Editor.addMark(editor, type, true);
  };

  return editor;
};
