import * as React from 'react';
import { Box, Flex, Dropdown, Menu, Icon, Text } from '@churni/styleguide';
import { useEditor } from 'slate-react';
import { LogicJumpContext } from './LogicJumpContext';
import { getPageIndex } from './utils';
import { PagesContext } from '../../containers';

export function PagePicker(props: {}): React.ReactElement {
  const { pageID, jumpPath, onPagePick } = React.useContext(LogicJumpContext);
  const pagesContext = React.useContext(PagesContext);
  const portalRef = React.useRef();
  const editor = useEditor();

  const pages = pagesContext.filter(p => p[1][0] > jumpPath[0]);
  const selectedPage = pages ? pages.find(page => page[0].id === pageID) : null;

  return (
    <Dropdown
      ref={portalRef}
      position={'south'}
      toggle={
        <Flex alignItems="center">
          <Text
            variant="subtitle4"
            color="primary"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {selectedPage ? (
              <>page {getPageIndex(editor, selectedPage[1])}</>
            ) : (
              <>page</>
            )}
          </Text>

          <Box color="primaryLight" ml={'4px'}>
            <Icon.ChevronDown />
          </Box>
        </Flex>
      }
    >
      {pages &&
        pages.map(page => (
          <Menu.Item
            key={page[0].id}
            onClick={() => {
              portalRef.current.close();
              onPagePick(page[0].id);
            }}
          >
            Page {getPageIndex(editor, page[1])}
          </Menu.Item>
        ))}
    </Dropdown>
  );
}
