/** @jsx h */
import { Element } from 'slate';
import h from '../../hyperscript';
import * as Page from './page';
import { Paragraph } from '..';

export function createMultipleChoiceQuestion(): Element {
  return (
    <question questionType={'multiple-choice'}>
      {createTitle()}
      {createQuestionBody('multiple-choice')}
    </question>
  );
}

export function createQuestionBody(type: 'text' | 'multiple-choice'): Element {
  if (type === 'text') {
    return <question-body>{createQuestionTextAnswer()}</question-body>;
  }
  return <question-body>{createMultipleChoiceItem()}</question-body>;
}

export function createMultipleChoiceQuestionWithPage(): Element {
  return (
    <page>
      <page-body>{createMultipleChoiceQuestion()}</page-body>
      {Page.createPageActions()}
    </page>
  );
}

export function createTextQuestion(): Element {
  return (
    <question questionType={'text'}>
      {createTitle()}
      {createQuestionBody('text')}
    </question>
  );
}

export function createTextQuestionWithPage(): Element {
  return (
    <page>
      <page-body>{createTextQuestion()}</page-body>
      {Page.createPageActions()}
    </page>
  );
}

export function createTitle(title = '') {
  return (
    <question-title>
      <text>{title}</text>
    </question-title>
  );
}

export function createMultipleChoiceItem(text = '') {
  return (
    <question-multiple-choice-item>
      <text>{text}</text>
    </question-multiple-choice-item>
  );
}

export function createQuestionTextAnswer() {
  return <question-text-answer>{Paragraph.create()}</question-text-answer>;
}
