import { Editor, Path } from 'slate';
import { Nodes } from '../types';

export const getNodesForPath = (editor: Editor, path: Path): Nodes => {
  const page = Editor.node(editor, path.slice(0, 1));
  const pageNode = Editor.node(editor, path.slice(0, 2));
  const node = Editor.node(editor, path);
  const parent = Editor.parent(editor, node[1]);

  return {
    page,
    pageNode,
    node,
    parent
  };
};
