import { Editor, NodeEntry } from 'slate';

export function getQuestions(editor: Editor, questions: NodeEntry[]) {
  return questions.reduce((acc, [question, questionPath]) => {
    try {
      const [, titlePath] = Editor.node(editor, questionPath.concat(0));
      const [questionWrapper, questionWrapperPath] = Editor.node(
        editor,
        questionPath.concat(1)
      );
      let answers = null;
      if (question.questionType === 'multiple-choice') {
        answers = questionWrapper.children.reduce(
          (acc, node: Node, i: number) => {
            return {
              ...acc,
              [node.id]: {
                index: i,
                text: Editor.string(editor, questionWrapperPath.concat(i)),
                id: node.id
              }
            };
          },
          {}
        );
      }

      return {
        ...acc,
        [question.id]: {
          type: question.questionType,
          id: question.id,
          required: !!question.required,
          multipleAnswers: !!question.multipleAnswers,
          title: Editor.string(editor, titlePath),
          answers
        }
      };
    } catch (err) {
      return acc;
    }
  }, {});
}
