import { BLOCKS } from '../../../types';
import { Editor, Transforms, Node } from 'slate';

import { Question } from '../../../helpers/elements';
import { genID } from '@churni/core';

export const withQuestionSchema = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    // Normalize question block
    if (node.type === BLOCKS.BLOCK_QUESTION) {
      // Ensure question has an id
      if (!node.id) {
        Transforms.setNodes(
          editor,
          {
            id: genID()
          },
          {
            at: path
          }
        );
      }

      // Ensure page has 2 children (Title, Body)
      if (
        !node.children[0] ||
        node.children[0].type !== BLOCKS.BLOCK_QUESTION_TITLE
      ) {
        return Transforms.insertNodes(editor, Question.createTitle(), {
          at: path.concat(0)
        });
      }

      if (
        !node.children[1] ||
        ![BLOCKS.BLOCK_QUESTION_BODY].includes(node.children[1].type)
      ) {
        Transforms.insertNodes(
          editor,
          Question.createQuestionBody(node.questionType),
          {
            at: path.concat(1)
          }
        );
      }

      if (node.children[2]) {
        Transforms.removeNodes(editor, { at: path.concat(2) });
      }
    }

    // Ensure multiple choice question has at least one item
    if (node.type === BLOCKS.BLOCK_QUESTION_BODY) {
      const parent = Node.parent(editor, path);

      if (node.children.length === 0) {
        Transforms.insertNodes(
          editor,
          parent.questionType === 'text'
            ? Question.createQuestionTextAnswer()
            : Question.createMultipleChoiceItem(),
          {
            at: path.concat(0)
          }
        );
      }

      for (const [child, childPath] of Node.children(editor, path)) {
        if (
          parent.questionType === 'multiple-choice' &&
          child.type !== BLOCKS.BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM
        ) {
          Transforms.removeNodes(editor, {
            at: childPath
          });
        }

        if (
          parent.questionType === 'text' &&
          ![BLOCKS.BLOCK_PARAGRAPH, BLOCKS.BLOCK_QUESTION_TEXT_ANSWER].includes(
            child.type
          )
        ) {
          Transforms.removeNodes(editor, {
            at: childPath
          });
        }
      }
    }

    // Ensure question item has an id
    if (node.type === BLOCKS.BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM) {
      const [parent] = Editor.parent(editor, path);

      const nodesWithSameID = parent.children
        ? parent.children.filter(n => n.id === node.id)
        : [];

      if (!node.id || nodesWithSameID.length > 1) {
        Transforms.setNodes(
          editor,
          {
            id: genID()
          },
          {
            at: path
          }
        );
      }
    }
    return normalizeNode([node, path]);
  };

  return editor;
};
