import { Editor, Transforms, Path } from 'slate';
import { ReferenceInterface } from '../../../containers';
import { ReactEditor } from 'slate-react';
import * as slugify from 'slugify';

export function insertReference(
  editor: Editor,
  referenceContainer: ReferenceInterface,
  result: object
) {
  Transforms.delete(editor, {
    unit: 'character',
    reverse: true,
    distance: referenceContainer.search.length + 1
  });

  const selectionBefore = editor.selection;

  const reference = {
    slug: slugify(referenceContainer.search),
    referenceType: 'variable'
  };

  editor.insertReference(reference);

  const newPath = Path.next(Path.next(selectionBefore.focus.path));

  Transforms.select(editor, {
    anchor: {
      path: newPath,
      offset: 0
    },
    focus: {
      path: newPath,
      offset: 0
    }
  });

  referenceContainer.hide();
  ReactEditor.focus(editor);
}
