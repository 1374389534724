import * as React from 'react';

export const ModeContext = React.createContext<'answer' | 'edit'>('answer');

export const useIsAnswerMode = () => {
  return React.useContext(ModeContext) === 'answer';
};

export const useIsEditMode = () => {
  return React.useContext(ModeContext) !== 'answer';
};
