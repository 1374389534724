import * as React from 'react';

import { Box, Flex, Radio, Text, Checkbox } from '@churni/styleguide';

import { useIsAnswerMode } from '../../containers';
import { Editor, Transforms } from 'slate';
import { ReactEditor, useEditor, RenderElementProps } from 'slate-react';
import { BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM } from '../../types';
import { Placeholder } from '../common';
import { QuestionContext } from './QuestionContext';

type QuestionItemProps = RenderElementProps & {
  required: boolean;
  multipleAnswers: boolean;
};

/*
 * Renderer for a question item block.
 */
export function BlockQuestionMultipleChoiceItem(
  props: RenderElementProps
): React.ReactElement {
  const { element, attributes, children } = props;
  const isAnswerMode = useIsAnswerMode();
  const { required, multipleAnswers } = React.useContext(QuestionContext);

  if (isAnswerMode) {
    return (
      <BlockQuestionMultipleChoiceItemAnswer
        element={element}
        required={required}
        multipleAnswers={multipleAnswers}
        attributes={attributes}
      >
        {children}
      </BlockQuestionMultipleChoiceItemAnswer>
    );
  }

  return (
    <BlockQuestionMultipleChoiceEdit
      element={element}
      attributes={attributes}
      required={required}
      multipleAnswers={multipleAnswers}
    >
      {children}
    </BlockQuestionMultipleChoiceEdit>
  );
}

export function BlockQuestionMultipleChoiceItemAnswer(
  props: QuestionItemProps
): React.ReactElement {
  const { attributes, element, children, multipleAnswers } = props;
  const editor = useEditor();
  const checkedNode = element.checked;
  const path = ReactEditor.findPath(editor, element);
  const [, questionWrapperPath] = Editor.parent(editor, path);

  const canCheck = useIsAnswerMode();

  const onClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (multipleAnswers) {
      Transforms.setNodes(editor, { checked: !checkedNode }, { at: path });
    } else {
      Transforms.setNodes(
        editor,
        { checked: false },
        {
          at: questionWrapperPath,
          match: n => n.type === BLOCK_QUESTION_MULTIPLE_CHOICE_ITEM
        }
      );
      Transforms.setNodes(editor, { checked: true }, { at: path });
    }
  };

  const actionProps = {
    className: 'editor-multiple-choice-item',
    contentEditable: false,
    sx: { userSelect: 'none' },
    width: 20,
    height: 20,
    mr: 1,
    checked: checkedNode,
    readOnly: true,
    disabled: !canCheck
  };

  return (
    <Flex
      {...attributes}
      onClick={canCheck ? onClick : null}
      sx={{ cursor: canCheck ? 'pointer' : 'default' }}
      alignItems={'center'}
    >
      <Box pt={'4px'}>
        {multipleAnswers ? (
          <Checkbox {...actionProps} />
        ) : (
          <Radio {...actionProps} />
        )}
      </Box>

      <ItemText element={element}>{children}</ItemText>
    </Flex>
  );
}

export function BlockQuestionMultipleChoiceEdit(
  props: QuestionItemProps
): React.ReactElement {
  const { attributes, element, children, multipleAnswers } = props;

  const actionProps = {
    className: 'editor-multiple-choice-item',
    contentEditable: false,
    sx: { userSelect: 'none', alignSelf: 'bottom' },
    width: 20,
    height: 20,
    mr: 1
  };

  return (
    <Flex alignItems={'center'} {...attributes}>
      <Box sx={{ pt: '4px' }}>
        {multipleAnswers ? (
          <Checkbox {...actionProps} />
        ) : (
          <Radio {...actionProps} />
        )}
      </Box>

      <ItemText element={element}>{children}</ItemText>
    </Flex>
  );
}

function ItemText(props: RenderElementProps): React.ReactElement {
  const { children, element } = props;

  const isAnswerMode = useIsAnswerMode();

  const placeholder = !element.children[0].text ? (
    <Placeholder sx={{ whiteSpace: 'nowrap' }}>
      <Text color={'secondaryText'}>Add a new option</Text>
    </Placeholder>
  ) : null;

  return (
    <Box sx={STYLES.item} {...(isAnswerMode ? { contentEditable: false } : {})}>
      {placeholder}
      <Text>{children}</Text>
    </Box>
  );
}

const STYLES = {
  item: {
    flex: 1,
    position: 'relative'
  }
};
