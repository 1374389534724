/** @jsx h */
import { Page, Question } from '../helpers';
import h from '../hyperscript';
import { Icon } from '@churni/styleguide';

export const collect = {
  name: 'Collect feedback',
  description: 'Simple template to collect customer feedback',
  create,
  Icon: Icon.Paragraph
};

function create(companyName = 'Pied Piper') {
  return (
    <editor>
      <header>
        <heading-1>{companyName}</heading-1>
        <paragraph>
          To process your cancellation, please follow the instructions below.
        </paragraph>
      </header>

      <page>
        <page-body>
          <question questionType={'multiple-choice'} required={true}>
            {Question.createTitle('Why are you canceling?')}
            <question-body>
              {Question.createMultipleChoiceItem(
                `Your product is too expensive`
              )}
              {Question.createMultipleChoiceItem(
                `I don't understand how to use your product`
              )}
              {Question.createMultipleChoiceItem(
                `You're missing a key feature I need`
              )}
              {Question.createMultipleChoiceItem(
                `It's not qualitative enough (Too buggy)`
              )}
              {Question.createMultipleChoiceItem(
                `I found something else I like better`
              )}
              {Question.createMultipleChoiceItem(`Other`)}
            </question-body>
          </question>
        </page-body>
        {Page.createPageActions()}
      </page>
      <page>
        <page-body>
          <question questionType={'text'} required={true}>
            {Question.createTitle(
              'What suggestion do you have to improve the product?'
            )}
            <question-body>{Question.createQuestionTextAnswer()}</question-body>
          </question>
        </page-body>
        {Page.createPageActions()}
      </page>
      <page>
        <page-body>
          <question required={true} questionType={'multiple-choice'}>
            {Question.createTitle('Would you still recommend us to a friend?')}
            <question-body>
              {Question.createMultipleChoiceItem(`Yes`)}
              {Question.createMultipleChoiceItem(`No`)}
            </question-body>
          </question>
        </page-body>
        <page-actions>
          <page-action-primary action="continue">
            Cancel my subscription
          </page-action-primary>
        </page-actions>
      </page>
    </editor>
  );
}
