import * as React from 'react';

import { Flex, Input, Text, Box, Dropdown, Icon } from '@churni/styleguide';
import { LogicJumpContext } from './LogicJumpContext';

export function VariableText(props: {}): React.ReactElement {
  const { variableSlug, variableValue } = React.useContext(LogicJumpContext);

  if (variableSlug || variableValue) {
    return (
      <Box>
        <Text variant="subtitle4">{variableSlug}</Text>
        <Text variant="subtitle4" color="primaryLight">
          {' '}
          ={' '}
        </Text>
        <Text variant="subtitle4">{variableValue}</Text>
      </Box>
    );
  }

  return <Text variant="body2">Select a variable</Text>;
}

export function VariablePicker(props: {}): React.ReactElement {
  const {
    variableSlug,
    onVariableSlugChange,
    variableValue,
    onVariableValueChange
  } = React.useContext(LogicJumpContext);

  return (
    <Box sx={{ position: 'relative' }}>
      <Flex alignItems="center">
        <Dropdown
          toggle={
            <Flex alignItems="center">
              <Box>
                <VariableText />
              </Box>
              <Box color="primaryLight" ml="4px">
                <Icon.ChevronDown />
              </Box>
            </Flex>
          }
        >
          <Box p={1}>
            <Flex alignItems="center" justifyContent="space-between" mb={1}>
              <Text variant={'body2'}>Name</Text>
              <Input
                ml={2}
                value={variableSlug}
                onChangeValue={onVariableSlugChange}
                placeholder="variable name"
                sx={{
                  width: 150,
                  py: '4px'
                }}
              />
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" mb={1}>
              <Text variant={'body2'}>Value</Text>
              <Input
                value={variableValue}
                onChangeValue={onVariableValueChange}
                placeholder="variable name"
                ml={2}
                sx={{
                  width: 150,
                  py: '4px'
                }}
              />
            </Flex>
          </Box>
        </Dropdown>
      </Flex>
    </Box>
  );
}
