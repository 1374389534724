/* eslint-disable jsx-a11y/no-autofocus */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createEditor, Editor as SlateEditor } from 'slate';
import { Slate, Editable, withReact, useSlate, useEditor } from 'slate-react';
import { renderElement } from './renderElement';

import { onKeyDown } from './handlers';
import {
  Palette,
  Reference,
  PageContext,
  ModeContext,
  useIsAnswerMode,
  VariablesContext,
  Refresh,
  PagesContext,
  JumpsContext,
  QuestionNodesContext,
  CompanyContext
} from './containers';
import { CommandPalette, ReferencePicker, HoveringMenu } from './components';
import { withPlugins } from './plugins';

import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { TemplateBlankslate } from './components/template';
import { renderLeaf } from './renderLeaf';
import { BLOCKS } from './types';
import { getAnswers } from './helpers/queries/question';

export const Editor = React.forwardRef(function EditorWrapperWithRef(
  props: {
    mode: 'answer' | 'edit';
    page?: {
      currentPage: number;
      previous: () => void;
      next: () => void;
    };
    variables?: {
      [slug: string]: string;
    };
    initialValue: object[];
    onChange?: (newValue: object[]) => void;
    company: {
      name: string;
    };
  },
  ref: React.Ref<any>
) {
  const {
    variables = {},
    page,
    mode = 'answer',
    initialValue,
    onChange,
    company
  } = props;

  return (
    <CompanyContext.Provider value={company}>
      <ModeContext.Provider value={mode}>
        <PageContext.Provider value={page}>
          <VariablesContext.Provider value={variables}>
            <Palette.Provider>
              <Reference.Provider>
                <DndProvider backend={HTML5Backend}>
                  <Refresh.Provider>
                    <EditorContent
                      initialValue={initialValue}
                      onChange={onChange}
                      ref={ref}
                    />
                  </Refresh.Provider>
                </DndProvider>
              </Reference.Provider>
            </Palette.Provider>
          </VariablesContext.Provider>
        </PageContext.Provider>
      </ModeContext.Provider>
    </CompanyContext.Provider>
  );
});

export const EditorContent = React.forwardRef(function EditorWithRef(
  props: {
    initialValue: object[];
    onChange: (newValue: object[]) => void;
  },
  ref: React.Ref<any>
): React.ReactElement {
  const { initialValue, onChange } = props;
  const [stateValue, setStateValue] = React.useState(initialValue);

  const handleChange = React.useCallback(val => {
    ReactDOM.flushSync(() => {
      setStateValue(val);
    });
  }, []);

  const editor = React.useMemo(
    () => withReact(withPlugins(createEditor())),
    []
  );

  React.useImperativeHandle(ref, () => ({
    getAnswers: () => {
      const nodes = Array.from(
        SlateEditor.nodes(editor, {
          match: n => [BLOCKS.BLOCK_QUESTION].includes(n.type),
          at: []
        })
      );
      return getAnswers(editor, nodes);
    },
    getValue: () => stateValue,
    setValue: (value: any) => {
      setStateValue(value);
    }
  }));

  return (
    <Slate
      editor={editor}
      value={stateValue}
      onChange={value => {
        handleChange(value);

        if (onChange) {
          onChange(value);
        }
      }}
    >
      <EditorWrapper>
        <EditorInner />
      </EditorWrapper>
    </Slate>
  );
});

export function EditorWrapper(props: {
  children: React.ReactElement;
}): React.ReactElement {
  const { children } = props;
  const editor = useEditor();

  const pages = [];
  const jumps = [];
  const questionNodes = [];

  for (const node of SlateEditor.nodes(editor, {
    match: n =>
      [BLOCKS.BLOCK_PAGE, BLOCKS.BLOCK_JUMP, BLOCKS.BLOCK_QUESTION].includes(
        n.type
      ),
    at: []
  })) {
    if (node[0].type === BLOCKS.BLOCK_PAGE) {
      pages.push(node);
    }

    if (node[0].type === BLOCKS.BLOCK_JUMP) {
      jumps.push(node);
    }

    if (node[0].type === BLOCKS.BLOCK_QUESTION) {
      questionNodes.push(node);
    }
  }

  const pagesKey = pages.map(p => `${p[0].id}-${p[1].join('.')}`).join(',');
  const jumpsKey = jumps.map(j => `${j[0].id}-${j[1].join('.')}`).join('.');
  const questionsKey = questionNodes
    .map(q => `${q[0].id}-${q[1].join('.')}`)
    .join(',');

  return React.useMemo(
    () => {
      return (
        <PagesContext.Provider value={pages}>
          <QuestionNodesContext.Provider value={questionNodes}>
            <JumpsContext.Provider value={jumps}>
              {children}
            </JumpsContext.Provider>
          </QuestionNodesContext.Provider>
        </PagesContext.Provider>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagesKey, jumpsKey, questionsKey]
  );
}

const EditorInner = React.forwardRef(function EditorInnerWithRef(
  props: {},
  ref: React.Ref<any>
): React.ReactElement {
  const editor = useSlate();
  const paletteContainer = Palette.useContainer();
  const referenceContainer = Reference.useContainer();
  const variablesContainer = React.useContext(VariablesContext);
  const isAnswerMode = useIsAnswerMode();

  if (isAnswerMode) {
    return (
      <Editable
        autoFocus
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
    );
  }

  return (
    <>
      <CommandPalette editor={editor} />
      <ReferencePicker editor={editor} />
      <HoveringMenu />
      <Editable
        autoFocus
        onKeyDown={(e: React.SyntheticEvent) =>
          onKeyDown({
            event: e,
            editor,
            paletteContainer,
            referenceContainer,
            variablesContainer
          })
        }
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
      <TemplateBlankslate />
    </>
  );
});
