import { Editor, Transforms, Element } from 'slate';
import { INLINE_REFERENCE } from '../../types';

export const withReferences = (editor: Editor) => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element: Element) => {
    return element.type === INLINE_REFERENCE ? true : isInline(element);
  };

  editor.isVoid = (element: Element) => {
    return element.type === INLINE_REFERENCE ? true : isVoid(element);
  };

  editor.insertReference = (data: any) => {
    const reference = {
      ...data,
      type: INLINE_REFERENCE,
      children: [{ text: '', marks: [] }]
    };
    Transforms.insertNodes(editor, reference);
  };

  return editor;
};
