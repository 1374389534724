import * as React from 'react';
import { Menu, Flex, Icon, Text, Dropdown, Box } from '@churni/styleguide';
import { LogicJumpContext } from './LogicJumpContext';
import { truncate } from './utils';

export function AnswerText(props: {}): React.ReactElement {
  const { selectedAnswer } = React.useContext(LogicJumpContext);

  if (selectedAnswer) {
    return (
      <Text variant="subtitle4" color="primary">
        {truncate(selectedAnswer.text)}
      </Text>
    );
  }

  return <Text variant="body2">select an answer</Text>;
}

export function AnswerPicker(): React.ReactElement {
  const {
    selectedAnswer,
    onAnswerPick,
    selectedQuestion: question
  } = React.useContext(LogicJumpContext);

  const portalRef = React.useRef();

  return (
    <Dropdown
      ref={portalRef}
      position={'south'}
      toggle={
        <Flex alignItems="center">
          <AnswerText />
          <Box color="primaryLight" ml={'4px'}>
            <Icon.ChevronDown />
          </Box>
        </Flex>
      }
    >
      <Box sx={{ minWidth: 300 }}>
        {Object.values(question.answers).map(answer => (
          <Menu.Item
            key={answer.id}
            onClick={() => onAnswerPick(question.id, answer.id)}
            active={selectedAnswer && selectedAnswer.id === answer.id}
          >
            {truncate(answer.text)}
          </Menu.Item>
        ))}
      </Box>
    </Dropdown>
  );
}
