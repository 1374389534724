import { Box } from '@churni/styleguide';
import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { BlockWrapper } from '../common';
import { BLOCK_LIST_OL } from '../../types';
import { useIsAnswerMode } from '../../containers';

/*
 * Renderer for a list block.
 */
export function BlockList(props: RenderElementProps): React.ReactElement {
  const { element, children, attributes } = props;

  const isAnswerMode = useIsAnswerMode();

  return (
    <Box {...attributes}>
      {isAnswerMode ? (
        <BlockListAnswer element={element}>{children}</BlockListAnswer>
      ) : (
        <BlockListEdit element={element}>{children}</BlockListEdit>
      )}
    </Box>
  );
}

export function BlockListEdit(props: RenderElementProps): React.ReactElement {
  const { element, children } = props;
  return (
    <BlockWrapper element={element}>
      <BlockListAnswer element={element}>{children}</BlockListAnswer>
    </BlockWrapper>
  );
}

export function BlockListAnswer(props: RenderElementProps): React.ReactElement {
  const { element, children } = props;

  if (element.type === BLOCK_LIST_OL) {
    return <Box as={'ol'}>{children}</Box>;
  }

  return (
    <Box as={'ul'} sx={styles.blockListUL}>
      {children}
    </Box>
  );
}

const styles = {
  blockListUL: {
    listStyle: 'disc'
  }
};
