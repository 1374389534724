import { Editor, NodeEntry } from 'slate';

export function getAnswers(editor: Editor, questions: NodeEntry[]) {
  return questions.reduce((acc, [question, questionPath]) => {
    const [, titlePath] = Editor.node(editor, questionPath.concat(0));
    const [questionWrapper, questionWrapperPath] = Editor.node(
      editor,
      questionPath.concat(1)
    );

    if (question.questionType === 'multiple-choice') {
      const answers = Object.values(
        questionWrapper.children.reduce((acc, node: Node, i: number) => {
          if (!node.checked) {
            return acc;
          }

          return {
            ...acc,
            [node.id]: {
              index: i,
              text: Editor.string(editor, questionWrapperPath.concat(i)),
              id: node.id
            }
          };
        }, {})
      );
      return {
        ...acc,
        [question.id]: {
          type: question.questionType,
          id: question.id,
          required: !!question.required,
          multipleAnswers: !!question.multipleAnswers,
          title: Editor.string(editor, titlePath),
          answers
        }
      };
    } else if (question.questionType === 'text') {
      return {
        ...acc,
        [question.id]: {
          type: 'text',
          id: question.id,
          required: !!question.required,
          title: Editor.string(editor, titlePath),
          answer: Editor.string(editor, questionWrapperPath)
        }
      };
    } else {
      return acc;
    }
  }, {});
}
